.attention-bar {
    color: $color-havsdjup;
    min-height: rem-calc(81px);
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-align-items: center;
    -ms-flex-item-align: center;
    display: flex;
    align-items: center;
    height: auto;

    .centered {
        display: table;
        margin: 0 auto;
        height: 100%;
        margin-bottom: 10px;
        color: $color-havsdjup;

        .centered-box {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            color: $color-havsdjup;
        }
        .input,
        .cta-btn {
            margin: 0px rem-calc(12px);
            color: $color-havsdjup;
        }
        p {
            margin: 0px rem-calc(25px);
            color: $color-havsdjup;
        }
    }
}

.cookies-jar {
    .attention-bar {
        background-color: $color-kust;
        .centered {
            .centered-box {
                @media #{$small-only} {
                    display: block;
                    margin-bottom: 10px;
                    color: $color-white;
                }
            }
            p {
                font-size: rem-calc(12px);
                line-height: rem-calc(14px);
                color: $color-white;
            }
        }
    }
}

.cookie-bar {
    position: fixed;
    background-color: $color-kust;
    bottom: 0;
    z-index: 99;
}
