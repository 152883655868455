.video-wrapper {
    h4 {
        padding: 0 rem-calc(2px);
        text-transform: capitalize;
    }

    .videoList {
        display: flex;
        flex-wrap: wrap;
    }
    .videoItem {
        flex: 0 1 25%;
        position: relative;
        overflow: hidden;

        &__iframe {
            width: 100%;
            height: rem-calc(150px);
            padding: rem-calc(5px) rem-calc(10px);
        }

        &__title {
            padding: 0 rem-calc(11px);
        }

        &__image {
            position: relative;
            border: 2px $color-fb-border;
            border-style: solid;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                fill: $color-fb-border;
                width: 50%;
                height: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.8;
                cursor: pointer;
                transition: all 0.2s ease-out;
            }

            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        @media #{$medium-only} {
            flex: 0 1 33.333333%;
        }

        @media #{$small-only} {
            flex: 0 1 100%;

            &__iframe {
                height: rem-calc(300px);
            }
        }
    }
}
