@mixin create-only-classes($class) {

  @media #{$small-only} {
    .#{$class}-small-only {
      @content;
    }
  }

  @media #{$medium-only} {
    .#{$class}-medium-only {
      @content;
    }
  }

  @media #{$large-only} {
    .#{$class}-large-only {
      @content;
    }
  }

  @media #{$xlarge-only} {
    .#{$class}-xlarge-only {
      @content;
    }
  }

  @media #{$medium-up} {
    .#{$class}-medium-up {
      @content;
    }
  }

  @media #{$large-up} {
    .#{$class}-large-up {
      @content;
    }
  }
}
