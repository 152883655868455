.inspiration-button {
    margin-top: rem-calc(54px);
    margin-bottom: rem-calc(81px);
}
.feed {
    .feed-content-wrapper {
        height: rem-calc(392px);
        > *:first-child {
            height: 100%;
        }
    }
    .background-image {
        background-size: cover;
        &.image-one {
            background: url('https://images.unsplash.com/photo-1427348693976-99e4aca06bb9?q=80&fm=jpg&s=93231f65ba0417b2fbe99d6643562938')
                no-repeat left center;
        }
        &.image-two {
            background: url('https://images.unsplash.com/photo-1424746219973-8fe3bd07d8e3?q=80&fm=jpg&s=69c2c4804c5892bd8f882afa7ee4e003')
                no-repeat center center;
        }
        &.image-three {
            background: url('https://images.unsplash.com/photo-1429277096327-11ee3b761c93?q=80&fm=jpg&s=b08939a8f8a5e08b781f895d09eb673e')
                no-repeat center center;
        }
    }
}

.hero-video-startpage {
    position: relative;
    height: 600px;
    background-color: black;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    @media (min-width: 901px) {
        background-image: none !important;
    }

    .hero-content {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        .button-container {
            margin-bottom: 1rem;
        }
    }

    .hero-video {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        @media (max-width: 900px) {
            display: none;
        }
    }
    iframe {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
}

form {
    h2 {
        text-align: center;
    }

    .success {
        width: 100%;
        padding: rem-calc(10px) rem-calc(20px);
        text-align: center;
        background-color: $color-go;

        span {
            @include font-h3;
            color: $color-light-text;
        }
    }

    .quick-invoice-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0 1.5rem;
        padding: rem-calc(36px) rem-calc(18px) rem-calc(16px);
        background: $color-kust;

        .form-datepicker-container {
            width: auto !important;
        }
        // * {
        //     margin-bottom: 0 !important;
        // }

        .submit {
            display: flex;
            width: 100%;

            button {
                display: block;
                width: 100%;
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
                background-color: var(--eld);
                border-bottom: rem-calc(5px) solid var(--eld-shadow);


                &:active {
                    border-bottom: rem-calc(3px) solid
                        rgba(lighten($color-darkgold-text, 10%), 0.5);
                    border-top: rem-calc(1px) solid
                        rgba(lighten($color-darkgold-text, 10%), 0.5);
                }

                &:hover {
                    background-color: var(--eld-shadow);
                }
            }

            @media #{$medium-up} {
                width: auto;
                padding-left: rem-calc(10px);

                button {
                    padding-left: rem-calc(10px);
                    padding-right: rem-calc(10px);
                }
            }
        }

        .input {
            position: relative;
            width: 100%;
            margin-top: rem-calc(16px);
            margin-bottom: rem-calc(20px);

            &:first-child {
                margin-top: 0;
            }

            .qi-label {
                @include font-small-text;
                position: absolute;
                bottom: calc(100% + 2px);
                left: 0;
                margin: 0;
                padding: 0;
                font-size: rem-calc(12px);
                font-weight: 500;
                line-height: 1;
                color: $color-white;

                small {
                    font-size: 0.7rem;
                    text-transform: none;
                }
            }

            input {
                @include font-form-field-value;
                margin: auto;
                // font-weight: 600;
            }

            .input-datepicker {
                .icon-calendar {
                    width: rem-calc(48px);
                    height: rem-calc(48px);
                    line-height: rem-calc(48px);
                }

                label {
                    display: none;
                }

                input {
                    height: auto;
                    padding: rem-calc(12px);
                    // font-size: rem-calc(16px);
                }
            }

            @media #{$medium-up} {
                flex-grow: 1;
                width: auto;
                padding-left: rem-calc(10px);
                // margin-left: rem-calc(20px);
                margin-top: 0;
                margin-bottom: auto;

                &:first-child {
                    padding-left: 0;
                    // margin-left: 0;
                }

                .qi-label {
                    @include font-small-text;
                    position: absolute;
                    bottom: calc(100% + 6px);
                    left: rem-calc(10px);
                    margin: 0;
                    padding: 0;
                    font-size: rem-calc(12px);
                    font-weight: 500;
                    line-height: 1;
                    color: $color-white;
                }

                &.type {
                    width: rem-calc(150px);

                    .qi-label {
                        left: 0;
                    }
                }

                // &.date {
                // max-width: rem-calc(180px);
                // }

                &.antal-personer,
                &.antal-natter {
                    width: rem-calc(100px);
                }

                &.email,
                &.phone {
                    max-width: rem-calc(230px);
                }

                &.message {
                    input {
                        font-weight: 500;
                    }
                }
            }
        }

        @media #{$medium-up} {
            flex-wrap: nowrap;

            .selectboxit-arrow-container {
                max-width: rem-calc(25px);
            }
        }

        @media #{$large-up} {
            .selectboxit-arrow-container {
                max-width: rem-calc(50px);
            }
        }
    }
}

.reviews-slider {
    position: relative;
    display: block;
    width: 100%;
    //max-width: 50rem;
    margin-top: 2rem;

    // @media #{$medium-up} {
    //   margin-top
    // }

    h2 {
        position: relative;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 1.4rem;
        text-transform: uppercase;

        @media #{$medium-up} {
            margin-bottom: 2rem;
            font-size: 2rem;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -0.8rem;
            left: 50%;
            width: 7rem;
            height: 3px;
            background-color: $color-fb-border;
            transform: translateX(-50%);

            @media #{$medium-up} {
                bottom: -1.2rem;
                width: 10rem;
            }
        }
    }

    .reviews-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        padding-top: 1rem;

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    .reviews-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: auto;
        padding: 0 2rem;

        @media #{$medium-up} {
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: -1px;
                width: 2px;
                height: 65%;
                background-color: $color-fb-border;
                transform: translateY(-50%);
                transition: 0.5s ease;
                opacity: 0;
            }
            &.slick-current {
                &:after {
                    opacity: 1;
                }
            }
        }

        q {
            padding: 0 0.5rem;
            font-size: 1rem;
            line-height: 1.2;
            margin-bottom: 1.2rem;

            @media #{$medium-up} {
                font-size: 1.1rem;
            }
            @media #{$large-up} {
                font-size: 1.2rem;
            }
        }

        em {
            height: 3em;
            padding: 0 0.5rem;
            font-size: 1.2rem;
            color: $color-discrete-text-alt1;
        }
    }
}
