.basket-item {
  background-color: $color-light-bg-alt3;
  height: rem-calc(81px);
  margin-top: rem-calc(3px);
  &.warning{
    border: 1px solid $color-warning;
  }
  .warning {
    @include font-checkout-title-italic;
    color: $color-warning;
  }
  .vertical-align {
    display: table;
    height: 100%;
    width: 100%;
    .select {
      margin-left: rem-calc(28px);
      .delimiter {
        margin: 0 rem-calc(7px) 0;
      }
    }
    .centered {
      display: table-cell;
      vertical-align: middle;
      img {
        margin: rem-calc(18px);
      }
      h3{
        @include font-checkout-title;
        display: inline-block;
        margin: 0;
      }
      .right {
        i,.link {
          margin: 0 rem-calc(28px) 0;
          font-size: rem-calc(21px);
        }
      }
    }
  }
}
.sum-vat-included {
  .oblique-small-font {
    margin-top: rem-calc(15px);
    @include font-paragraph-oblique-small;
  }
}
.checkout-item-wrapper {
  background-color: $color-light-bg-alt3;
  color: $color-dark-text;
  padding-right: rem-calc(36px);
  padding-left: rem-calc(28px);
  .columns {
    padding:0 0.5rem!important;
  }
  h4 {
    @include font-checkout-item-title;
  }
  p {
    @include font-checkout-item-paragraph;
  }
  img {
    display: block;
    margin-top: rem-calc(28px);
    width: 100%;
  }
  a {
    color: $color-darkgold-text;
    text-decoration: underline;
  }
  .checkout-item {
    height: rem-calc(192px);
  }
  .feedback-wrapper {
    padding-bottom: rem-calc(29px);
  }
  .gold-border {
    height: rem-calc(3px);
    width: rem-calc(119px);
    background-color: $color-gold-border;
    display: block;
    margin-top: rem-calc(13px);
    margin-bottom: rem-calc(21px);
  }
  .full-height {
    height: 100%;
  }
  .warning-text {
    color: $color-warning;
  }
  .centered {
    .align-middle {
      display: table;
      height: 100%;
      > .columns {
        display: table-cell;
        vertical-align: middle;
      }
      > .columns, > [class*="column"] + [class*="column"]:last-child {
        float: none;
      }
    }
  }
  &:hover {
    background-color: $color-light-bg-alt4;
  }
  .right {
    p {
      margin-bottom: rem-calc(10px);
    }
  }
}
.checkout-item-feedback {
  color: $color-dark-text;
//  min-height: rem-calc(50px);
  margin-bottom: rem-calc(10px);
  padding-bottom:5px;

  &.warning {
    background-color: $color-gentle-warning-light-bg;
  }
  &.check {
    background-color: $color-go-light-bg;
    .icon-check {
      color: $color-go;
    }
  }
  .centered {
    display: table;
    height: 100%;
    .centered-box {
      display: table-cell;
      vertical-align: middle;
    }
  }
  i {
    font-size: rem-calc(21px);
    margin: 0 rem-calc(12px) 0 rem-calc(13px);
  }
  p {
    display: inline-block;
    margin: 0;
    @include font-paragraph-small;
    span {
      @include font-paragraph-small-bold;
    }
  }
}
.checkout-bundle{
  padding: rem-calc(18px);
  background-color: $color-light-bg-alt3;
  color: $color-dark-text;
  margin: 2px;
  img {
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }
  h3 {
    @include font-checkout-title;
    margin: 0;
  }
  table {
    background: transparent;
    border: none;
    margin-bottom: 0;
    .table-cell {
      vertical-align: middle;
      padding: 0;
      &.less-padding {
        .cta-btn {
          padding-left: rem-calc(14px);
          padding-right: rem-calc(14px);
        }
      }
    }
  }
  .float-left{
    float: left;
  }
  .float-right{
    float: right;
  }
  .delimiter{
    height: 1px;
    background-color: $color-discrete-border-alt2;
    display: block;
    margin: rem-calc(18px) 0;
  }
  .image-wrapper{
    width: 40%;
    margin-right: rem-calc(0px);
  }
  .text-wrapper{
    padding-left:1rem;

    width: 56%;
    height: 100%;
    position: relative;
    h3{
      margin-top: rem-calc(7px);
    }
    .right{
      width: 100%;
      p{
        @include font-paragraph-small;
        color: $color-discrete-text-alt1;
        text-decoration: line-through;
        text-align: right;
        margin: 0px rem-calc(16px) 0 0;
        position: absolute;
        bottom: rem-calc(32px);
      }
      h3{
        @include font-checkout-price;
        color: $color-warning;
        margin: 0px rem-calc(16px) 0 0;
        line-height: rem-calc(24px);
      }
      .cta-btn{
        margin: 0;
      }
    }
  }
}
.checkout-payment-option-container{
  border-bottom: 1px solid $color-light-border-alt3;
  height: 80px;
  line-height: 80px;
  position: relative;
  .text-container {
    display:inline-block;
    max-width: 175px;
    h4 {
      @include font-paragraph-bold;
      margin-bottom:0;
      padding-top: 10px;
    }
    p{
      @include font-paragraph-small;
      color: $color-discrete-text-alt1;
      margin-bottom: 0;
    }
  }
  .radiobutton-wrapper{
    width: 49px;
    padding-left: rem-calc(16px);
    display: inline-block;
    float: left;
    input {
      height: 0;
    }
    label {
      position: relative;
      top: 5px;
    }
  }
  .image-container {
    width: 58px;
    display: inline;
    margin-right: 10px;
    float:left;
  }

  @media #{$medium-up}{
    border: 1px solid $color-light-border-alt3;
    .radiobutton-wrapper {
      width: 58px;
    }
    .text-container {
      max-width: 280px;
    }
  }
}
.signup-form-container {
  float: none;
  margin: 0 auto;
  display: block;
  margin-top: 54px;
  .form-input-container input {
    margin-bottom: 10px;
  }
  .cta-btn {
    width: 100%;
    padding: 0;
    margin-top: 9px;
    margin-bottom: rem-calc(15px);
  }
  .signup-sub-form {
    background-color: $color-light-bg-alt3;
    height: 50px;
    @include font-paragraph-bold;
    line-height: 50px;
    margin-bottom: 3px !important;
    a {
      width: 100%;
      display: inline-block;
      color: $color-dark-text;
      span {
        font-size: 8px;
      }
    }
  }
  .signup-sub-form:last-child {
    margin-bottom: rem-calc(18px);
  }
  @media #{$medium-up} {
    .form-input-container:not(.right-column) {
      margin-right: 10px;
    }
    .form-input-container.right-column {
      margin-left: 11px;
    }
    h4 {
      margin-top: 44px;
      margin-bottom: 36px;
    }
    .cta-btn {
      margin-bottom: 0;
    }
    textarea {
      height: 80px;
      margin-bottom: 13px;
    }
    .checkbox-wrapper input {
      height: 0px;
    }
    .checkbox-wrapper label {
      margin-bottom: 10px;
    }
    .form-input-container input {
      margin-bottom: 9px;
    }
  }
}


.checkout-item-expanded-container{
  width: auto;
  .checkout-item-expanded-top{
    background-color: $color-light-bg-alt4;
    min-height: 563px;
    padding: 28px 28px 28px;
    border-bottom:1px solid white;
    .image-container {
      margin-bottom: 21px;
      width:100%;
      img {
        object-fit: cover;
        width: 100%;

      }
    }
    .checkbox-container {
      @include font-paragraph-bold;
      ul li {
        font-size: 14px;
        margin-bottom: 2px;
        span {
          font-size: 10px;
          color: $color-gold-text;
          margin-right: 10px;
        }
      }
    }
    .checkout-item-left.left{
      float:left;
      width:30%!important;
    }
    .checkout-item-right.right{
      float:left;
      width: 70%!important;
      padding-left:10px;
    }
    .checkout-item-right{
      width: 561px;
      h2{
        margin: 0;
        padding: 0;
        @include font-checkout-item-title;
        span{
          color: $color-warning;
        }
      }
      .gold-border{
        height: rem-calc(3px);
        width: rem-calc(119px);
        background-color: $color-gold-border;
        display: block;
        margin: 13px 0;
      }
      p {
        @include font-checkout-item-paragraph;
        margin-bottom: 26px;
        .read-more {
          @include font-form-field-title;
          color: $color-gold-text;
          text-decoration: underline;

        }

      }
      .two-columns{
        .availablility-container {
          float: left;
          display: inline-block;
          margin-right: 21px;
          h5 {
            @include font-form-field-title;
            margin-top: 0;
            margin-bottom: 3px;
          }
          .datepicker-content {
            width: 222px;
            background-color: white;
          }
        }
        .selectbox-container{
          float: left;
          .form-select-container {
            display: block;
            margin-bottom: 13px;
          }
          .form-select-container.last{
            margin-bottom: 0;

          }
          .selectboxit{
            width: 220px!important;
          }
          .selectboxit-options{
            width: 220px!important;
          }
        }
      }
    }
    .checkout-item-left {
      width: 237px;
    }
  }
  .checkout-bottom-right{
    width: 70%;
  }
  .checkout-item-expanded-bottom{
    padding: 36px;
    //    height: 138px;
    background-color: $color-light-bg-alt4;
    h4 {
      @include font-checkout-item-title;
      text-align: right;
      color: $color-warning;
      margin-bottom: 0;
    }
    .price {
      text-align: right;
      padding-right: 28px;
      @include font-paragraph-small-bold;
    }
    .fine-print {
      width: 30%;
      margin-top: 10px;
      p {
        @include font-paragraph-oblique-small;
      }
    }
  }
}