.checkout-wrapper {
  margin-bottom: rem-calc(81px);
  .row .receipt-wrapper {
    padding-left: rem-calc(20px);
  }
  .headline {
    color: $color-large-headline;
    margin-bottom: rem-calc(29px);
    &.with-top-margin {
      margin-top: rem-calc(20px);
    }
  }
  .title-wrapper {
    margin-top: rem-calc(18px);
    margin-bottom: rem-calc(36px);
    h1,h2,h3,h4,h5 {
      color: $color-large-headline;
      margin: 0;
    }
    p {
      @include font-paragraph;
      margin: rem-calc(30px) 0 0 0;
    }
  }
  .row.collapse.checkout-row-wrapper {
    margin-bottom: rem-calc(3px);
  }
  .button-wrapper {
    margin: rem-calc(25px) 0px;
  }
  .checkout-form-delimiter {
    margin: rem-calc(25px) 0px;
  }
  .cta-btn.fb{
    margin-bottom: rem-calc(30px);
  }
  .checkout-action-buttons{
    margin-top: rem-calc(16px);
    margin-bottom: rem-calc(16px);
  }
  .selectboxes-checkout-item .image-container{
    display:inline-block!important;
    float: left;
    width: 20%;
    img {
//      height: 100%;
//      width: 100%;
      object-fit: cover;
    }
  }
  .selectboxes-checkout-item .price-container {
    width: 80%;
    float: left;
    height: 100%;
  }
}
