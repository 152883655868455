
.availability-checker {
  padding-top: 18px;
  height: rem-calc(109px);
  border: 3px solid $color-light-border-alt3;
  border-bottom: none;
  .centered {
    display: table;
    margin: 0 auto;
    height: 100%;
    .centered-box {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    * {
      margin: 0px 0px 0px rem-calc(21px);
    }
  }
}
