@media #{$small-only} {
  // Overlay för kvittot
  .checkout-item-feedback {
    margin-top: 20px;
    padding: 10px;

    p {
      float: right;
      width: 88%;
    }
  }
  .checkout-item-feedback i {
    margin: 0;
  }
  .mobile-grey-overlay {
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    width: 100%;
    z-index: 9;
    display: none;
  }
  // Fix för iphone så att den inte zoomar på inputs
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
      background: #eee;
    }
  }

  // General
  // Footer
  .footer {
    padding: rem-calc(29px) rem-calc(29px);
    .footer-title {
      margin: rem-calc(20px) rem-calc(0px) rem-calc(10px) !important;
    }
  }

  // Mobile menu
  .menu-expanded-container {
    ul {
      width: 100%;
    }
  }

  // Start page
  .hero-image-startpage {
    background-position: center center;
    // p {
    //margin-top: rem-calc(70px);
    // }
    .columns {
      width: auto !important;
    }
  }
  .feed-textbox-12-col {
    padding: rem-calc(25px) rem-calc(40px);
    div > h4 {
      margin: 0;
      width: auto;
    }
  }

  // Feed
  .feed .feed-content-wrapper {
    height: auto;
    img {
      width: 100%;
    }
    .feed-textbox {
      padding: rem-calc(40px) 0;
      h4 {
        margin-bottom: rem-calc(5px);
      }
      p {
        margin-bottom: 0;
      }
      .ghost-btn {
        margin-top: 30px;
      }
    }
  }

  // Filterpage
  .filter-wrapper {
    padding-top: rem-calc(30px);
    margin-top: 0;
    //Top textbox wrapper
    .textbox-wrapper {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    //Sort bar filter page
    .sort-bar-wrapper {
      margin: 0;
      .sort-bar-mobile .tabs-content {
        padding-bottom: 0;
        margin: 0;
        margin-bottom: 5px;
        .content {
          float: left;
          .mobile-show-filter {
            text-decoration: underline;
            font-weight: bold;
          }
        }
        span {
          float: left;
          margin-top: 0;
        }
        h2 {
          float: right;
          font-size: 12px;
          margin-top: 2px;
          line-height: 12px;
        }
      }
    }
    // Filterpage objects
    .filter-objects-container {
      .prodbox {
        padding-bottom: 7px;
      }
      .prodbox.grid,
      .prodbox.list {
        border: none;
        margin-bottom: 5px;

        .columns {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
        .image-wrapper {
          float: left;
          margin-top: 0;
          //          width: 33%;
        }
        .text-wrapper {
          float: left;
          //          widtH: 67%;
          margin-top: 0;
          margin-left: 0;
          margin-right: 0;
          padding-left: 0rem;
          .cta-btn {
            padding: 10px 10px !important;
            .value {
              margin-right: 0;
            }
          }

          p.show-for-small-only {
            margin-bottom: 5px;
            line-height: 16px;
          }
          .prod-box-mobile-button-container {
            .price-container {
              float: right;
              text-align: right;
              width: 40%;
              padding-top: 3px;
              padding-right: 4%;
            }
            p {
              margin-bottom: 0;
              font-family: "GT-Haptik-Bold";
              &.price-title {
                font-weight: bold;
              }
              &.price {
                font-size: rem-calc(16px);
                color: #926e39;
                font-weight: bold;
              }
            }
            .cta-btn {
              position: static;
              padding-left: 10%;
              padding-right: 10%;
              float: right;
            }
          }
        }
        .gold-border {
          display: none;
        }
        h4 {
          font-size: 15px;
          line-height: 16px;
          margin-top: 0;
          margin-bottom: rem-calc(2px);
        }
        .cta-btn {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
    // Mobile filter popup
    .row .filter-bar-wrapper {
      display: none;
      position: absolute;
      z-index: 10;
      background-color: white;
      padding: 0;
      width: 80%;
      right: 0;
      top: 0;
      .filter-sidebar {
        h2 {
          font-size: 17px;
          //          margin-right: 20px;
          text-align: left;
          margin-left: 20px;
          span {
            float: right;
            margin-right: 10px;
            display: inline !important;
            // position: absolute;
            // margin-left: 10px;
            // right: 25px;
            font-size: 30px;
            color: black;
          }
        }
        .mobile-filter-buttons {
          padding-top: 1.75rem;
          padding-bottom: 1.75rem;
          .form-button-wrapper:first-child {
            margin-left: 1.75rem;
            margin-right: 0;
            float: none;
          }
          .form-button-wrapper {
            display: inline-block;
            margin-right: 1.75rem;
            float: right;
          }
        }
      }
    }
  }

  // Product page
  .productpage-wrapper {
    h1 {
      font-size: 1.3rem;
    }
    .title-wrapper {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      h1 {
        margin-right: 0;
      }
      a {
        display: none;
      }
    }
    .product-information-wrapper {
      .centered {
        display: block;
        .centered-box {
          display: block;
          float: none;
          .tell-a-friend-wrapper {
            padding-top: 8px;
            text-align: left;
          }
        }
      }
    }
    .product-infobox {
      min-height: 0;
      p {
        margin-bottom: 15px !important;
        //        background-color:yellow;
      }
      // .cta-btn {
      //        margin-top:20px;
      // }
    }
    .product-infobox-sticky {
      display: none;
    }
  }

  // Slick slider thumbs
  .image-carousel-container {
    .main-carousel-image {
      #main-carousel {
        .slick-slide {
          height: 240px;
          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
  .image-carousel-container .slick-slide {
    .carousel-textbox {
      margin-top: 0;
      h4 {
        font-size: 13px;
        word-break: break-word;
      }
    }
  }
  .image-carousel-thumbs-container .image-carousel-thumbs {
    width: inherit;
    .slick-slide {
      padding-right: 2px;
      padding-left: 2px;
      img {
        object-fit: cover;
        display: block;
      }
    }
  }

  // Article page
  .articlepage-wrapper {
    form {
      h3 {
        margin-top: rem-calc(6px);
      }
    }
    .form-datepicker-container {
      width: 100%;
    }
    .sticky-anchorlink {
      display: none;
    }
    .article-block-wrapper {
      margin-top: 10px !important;
      iframe {
        width: 100%;
      }
      h1 {
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
      }
      h2 {
        font-size: 1.1rem;
      }
    }
  }

  //Shopping cart
  .cart-mobile-wrapper {
    position: absolute;
    z-index: 10;
    right: 0;
    width: 100%;
    background-color: transparent;
    padding: 0 rem-calc(20px);
    top: 50px;
    .receipt {
      border: none;
    }
    .receipt-wrapper {
      background-color: white;
      padding: 0 !important;
      .icon-close {
        float: right;
        padding-right: 5px;
        padding-top: 5px;
        cursor: pointer;
      }
      .receipt-button-container {
        text-align: center;
        a {
          margin-top: 10px;
          margin-bottom: 10px;
          width: 50%;
        }
      }
    }
  }

  // Checkout
  .checkout-wrapper {
    .mobile-padding-checkout {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .title-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
      h1 {
        margin-right: 0;
        font-size: 1.3rem;
      }
      p {
        margin-top: 10px;
      }
    }
    .content-wrapper {
      display: table;
      .float-left,
      .float-right {
        float: none;
        display: table-cell;
        vertical-align: middle;
        h3 {
          margin-right: rem-calc(8px);
        }
      }
    }
    .mobile-padding-checkout {
      .checkout-item-wrapper img {
        margin-top: 0;
      }
      h4 {
        margin: 0;
        margin-top: 4px;
        padding: 0;
        &.warning-text {
          line-height: 36px !important;
        }
      }
      .checkout-item-wrapper {
        .centered {
          .align-middle p {
            margin-bottom: 8px;
          }
          padding-left: rem-calc(10px) !important;
          .cta-btn {
            padding-left: 15px;
            padding-right: 15px;
            margin-left: 5px;
            height: 36px;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    .mobile-padding-checkout {
      padding: 0 !important;
      .availability-checker {
        border: none;
        height: auto;
        padding-left: rem-calc(8px);
        padding-right: rem-calc(8px);
        .checkout-header-options-mobile {
          padding-left: 0;
          padding-right: 0;
          .column {
            padding-right: 0;
            padding-left: 0;
          }
          .form-datepicker-container {
            margin-bottom: 6px;
          }
          .form-datepicker-container,
          .form-select-container {
            margin-bottom: 4px !important;

            .selectboxit-arrow-container {
              display: none;
            }

            #availability-element_idSelectBoxItText {
              max-width: none !important;
              width: 100% !important;
              padding-right: 7px;
              line-height: 32px;
            }

            input,
            select,
            #availability-element_idSelectBoxIt {
              background-color: #181f2f !important;
              text-align: right;
              color: white;
            }
            label {
              position: absolute;
              top: 6px;
              left: 10px;
              color: white;
              z-index: 20;
              span {
                display: inline !important;
              }
            }
          }
        }
      }
      .checkout-objects-container {
        h2 {
          display: none;
          padding-left: 0.4rem;
          padding-right: 0.4rem;
        }
        .checkout-item-wrapper .centered .align-middle > .columns,
        .checkout-item-wrapper
          .centered
          .align-middle
          > [class*="column"]
          + [class*="column"]:last-child {
          float: left;
        }

        .checkout-item-wrapper {
          padding-left: 8px;
          padding-right: 8px;
          padding-bottom: 8px;
          .checkout-item {
            height: auto;
            .text-right:not(.warning-text) {
              display: none;
            }
            .full-height {
              height: auto;
              .medium-4 {
                width: 33%;
                &.checkout-image-container {
                  float: left;
                  width: 33%;
                  height: 100px;
                  position: relative;
                  display: inline-block;
                  overflow: hidden;
                  > img {
                    position: absolute;
                    top: 50%;
                    min-height: 100%;
                    display: block;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    min-width: 100%;
                    object-fit: cover;
                    margin-top: 8px;
                    margin-bottom: 8px;
                  }
                }
              }
              .medium-8 {
                width: 66%;
                &.centered {
                  padding-left: rem-calc(10px) !important;

                  .gold-border {
                    display: none;
                  }

                  .align-middle {
                    p {
                      margin-bottom: 8px;
                    }
                    h4 {
                      font-size: 16px;
                      margin-bottom: 0;
                      line-height: 24px;
                    }
                    .medium-7 {
                      p.show-for-small-only {
                        font-size: 12px;
                        margin-bottom: 8px;
                        line-height: 13px;
                      }
                    }
                    .medium-5 {
                      display: block;
                      .cta-btn {
                        padding-left: 15px;
                        padding-right: 15px;
                        margin-left: 5px;
                        height: 36px;
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .checkout-image-container {
      width: 33%;
      height: 100px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      > img {
        position: absolute;
        top: 50%;
        min-height: 100%;
        display: block;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        object-fit: cover;
      }
    }
    .checkout-item-expanded-container {
      width: auto !important;
      h2 {
        line-height: 18px !important;
        font-size: 18px !important;
      }
      .gold-border {
        display: none !important;
      }
      .checkout-item-expanded-top {
        padding: 8px;
        .checkout-item-left {
          float: none !important;
          width: 33%;
          .checkbox-container {
            display: none;
          }
          .image-container {
            margin-right: 10px;
            float: left;
            width: 100%;
            height: 100px;
            position: relative;
            display: inline-block;
            overflow: hidden;
            > img {
              position: absolute;
              top: 50%;
              min-height: 100%;
              display: block;
              left: 50%;
              transform: translate(-50%, -50%);
              min-width: 100%;
              object-fit: cover;
            }
          }
        }
        .checkout-item-right {
          float: left !important;
          width: 66% !important;
          padding-left: 8px;
          h2 {
            line-height: 16px !important;
            font-size: 16px !important;
          }
        }
        .right {
          width: auto !important;
          //          span {
          ////            line-height: 25px;
          //          }
        }
        .left {
          width: auto !important;
        }
      }

      .checkout-item-expanded-bottom {
        padding: 10px;
        height: auto;
        .checkout-action-buttons {
          margin-top: 10px;
        }
        .cta-btn {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .two-columns {
        padding-bottom: 20px;
      }
    }
    .selectboxes-checkout-item {
      .basket-item {
        position: relative;
        margin: 0;
        margin-bottom: 5px;
        float: left;
        width: 100%;
        height: auto;
        padding: 0.4rem;
        .vertical-align {
          position: static;

          .centered {
            display: block;
            img {
              margin: 0;
            }
          }
          .icon-trashcan {
            position: absolute;
            top: 50%;
            /* left: 50%; */
            transform: translate(-50%, -50%);
          }
        }
        .image-container {
          width: 20%;
          float: left;
          padding-right: 10px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            min-height: 55px;
            min-width: 55px;
          }
        }
        .price-container {
          width: 80%;
          float: left;
          .mobile-price-container .right {
            float: none;
          }
          .vertical-align {
            .centered {
              // .vertical-align {
                //                max-width: 77%;
                //                position: absolute;
                //                top: 50%;
                //                transform: translate(0%, -50%);
              // }
              .right {
                float: none !important;
              }
              // > h3 {
                //                text-decoration: underline;
              // }
              .link {
                float: right;
              }
            }
          }
        }
      }
    }
    .checkout-bundle {
      padding: 8px;
      height: auto;
      h3 {
        font-weight: bold;
        margin-top: 0;
        font-size: 16px;
      }
      .text-wrapper {
        position: static;
        height: auto;
        .delimiter {
          display: none;
        }
        h3 {
          font-size: 16px;
          bottom: 8px;
          position: static;
          margin-right: 8px;
          line-height: 36px;
        }
        .right {
          width: auto;
        }
        .right .cta-btn {
          bottom: 8px;
          right: 8px;
          padding-left: 16px;
          padding-right: 16px;
          position: static;
          max-width: 100%;
        }
      }
      .image-wrapper {
        float: left;
        margin-right: 8px;
        min-width: 100px;
        min-height: 100px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        > img {
          position: absolute;
          top: 50%;
          min-height: 100%;
          display: block;
          left: 50%;
          transform: translate(-50%, -50%);
          min-width: 100%;
          object-fit: cover;
        }
      }
    }
    .title-wrapper.small-12.columns {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
    }
    .no-mobile-padding {
      padding-left: 0;
      padding-right: 0;
    }
    .checkout-signup-form {
      padding: 0;
      .signup-form-container {
        margin-top: 10px;
      }
      .checkout-form-delimiter {
        display: none;
      }
    }
    .checkout-item-wrapper {
      padding-left: 0;
      padding-right: 0;
      .full-height {
        height: auto;
        .medium-4 {
          width: 33%;
        }
        .medium-8 {
          width: 66%;
        }
      }
    }
    .checkout-item {
      .medium-4 {
        width: 33%;
      }
    }
    .availability-checker {
      border: 0;
      .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .signup-sub-form.show-for-small-only {
      clear: both;
      .mobile-signup-container {
        display: none;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
