.articlepage-wrapper {
    .form-datepicker-container {
        width: 50%;
    }
    .calendar-container {
        margin-top: rem-calc(16px);
    }
    .radiobutton-wrapper, .checkbox-wrapper, .slider, .form-datepicker-container, .selectboxit-container {
        margin-bottom: rem-calc(16px);
    }
}