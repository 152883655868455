@mixin textbox($h4-margin-top, $h4-margin-bottom) {
    div > h4,
    div > h1 {
        color: $color-gold-text;
        margin-top: $h4-margin-top;
        margin-bottom: $h4-margin-bottom;
    }
    div > p {
        color: $color-sjoskum;
    }
    @media #{$medium-up} {
        div > p {
            margin-top: rem-calc(36px);
            margin-bottom: 0; //$ms-54;
        }
        &.with-btn {
            > .ghost-btn {
                margin-top: rem-calc(0);
                margin-bottom: $ms-54;
            }

            p {
                margin-bottom: rem-calc(36px);
            }
        }
    }
}

.feed-textbox {
    $h4-margin-top: 0; //27px;
    $h4-margin-bottom: 27px;
    @include textbox($h4-margin-top, $h4-margin-bottom);
    padding-bottom: 0.5rem;
    // TODO: Lagga till eventuell padding pa p elementet
    div > p {
        @include font-textbox;
    }

    .vertical-center-container {
        &.aligned-left {
            padding-left: $ms-54;
            padding-right: $ms-54;
        }
    }

    &, &.alt1 {
        background-color: $color-light-bg-alt1;
    }

    &.alt2 {
        background-color: $color-light-bg-alt2;
    }

    &.alt3 {
        background-color: $color-light-bg-alt3;
    }

    &.alt4 {
        background-color: $color-light-bg-alt4;
    }

    @media #{$medium-up}{
        $h4-margin-top: 0; //$ms-54;
        $h4-margin-bottom: $ms-36;
        @include textbox($h4-margin-top, $h4-margin-bottom);
        div > p {
            @include font-h2;
        }
    }

    &.with-btn {
        > .ghost-btn {
            margin-top: rem-calc(6px);
            //margin-bottom: rem-calc(21px);
        }
    }
}

.feed-textbox-12-col {
    text-align: center;
    // $h4-margin-top: 54px;
    $h4-margin-top: 0;
    $h4-margin-bottom: 0;

    div > h4 {
        color: $color-gryning;
        margin-top: $h4-margin-top;
        margin-bottom: $h4-margin-bottom;
    }

    div > p {
        color: $color-dark-text;
    }

    @media #{$medium-up} {
        div > p {
            margin-top: rem-calc(36px);
            margin-bottom: $ms-54;
        }

        &.with-btn {
            > .ghost-btn {
                margin-top: rem-calc(0);
                margin-bottom: $ms-54;
            }

            p {
                margin-bottom: rem-calc(36px);
            }
        }
    }

    .feedbox-text {
        @include font-textbox;
        line-height: 1.2em;
        margin-top: 10px;
        padding: 0 2rem;
        font-size: 2.1rem;
        @media (max-width: 720px) {
            padding: 0;
        }
    }

    &, &.alt1 {
        background-color: none;
    }

    &.alt2 {
        background-color: $color-light-bg-alt2;
    }
}

.carousel-textbox {
    margin-top: 30px;
    // TODO: Lagga till eventuell padding pa p elementet
    p {
        @include font-paragraph-small;
        @media #{$medium-up}{
            @include font-h2
        }
    }

    > p {
        margin-bottom: rem-calc(17px);
        padding-bottom: rem-calc(17px);
    }

    > * {
        padding-left: rem-calc(16px);
        padding-right: rem-calc(16px);
    }

    @include textbox(16px, 15px);
    background-color: $color-light-bg-alt1;
}
