.articlepage-wrapper {
  .image-carousel-container {
    margin-bottom: 0;
  }
  .row.article-block-wrapper, .article-block-wrapper {
    margin-top: rem-calc(45px);

    h1 {
      color: $color-large-headline;
      margin: 0;
    }
    .prodbox {
      height: 540px;
    }
    &.article-inline-content {
      .prodbox {
          height: auto;
      }
  }

  }
  [data-magellan-expedition], [data-magellan-expedition-clone] {
    max-width: none;
    min-width: 0;
    width: inherit;
    padding: 1px 1px 0 0;
    background-color: inherit;
    left: auto;
  }
}