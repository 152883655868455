.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}
.selectboxit-container * {
  @include font-form-field-value;
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}
/* Button */
.selectboxit-container .selectboxit {
  height: 50px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block;
  position: relative;
  border: 1px solid $color-sjoskum;
}
/* Height and Vertical Alignment of Text */
.selectboxit-container span, .selectboxit-container .selectboxit-options a {
  height: 50px; /* Height of the drop down */
  line-height: 50px; /* Vertically positions the drop down text */
  display: block;
}
/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}
/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
/* Button Text */
.selectboxit-text {
  text-indent: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.selectboxit .selectboxit-option-icon-container {
  margin-left: 5px;
}
/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 100%;  /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 9999999999999;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  li {
    border-bottom: 1px solid $color-light-bg-alt4;
  }
}
/* Individual options */
.selectboxit-option .selectboxit-option-anchor{
  padding: 0 2px;
}
/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}
/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  text-indent: 5px; /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}
/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}
/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default;
}
/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  background-color: $color-dark-bg;

}
/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 15px;
  right: 0;
  left: 0;
  color: white;
  text-align: center;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
}
.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}
.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}
/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

.selectboxit-btn {
  background-color: white;
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
}

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
  color: #333333;
  background-color: $color-light-bg-alt3;
  .selectboxit-arrow-container{
    .mini &{
      background-color: $color-light-bg-alt3;

    }
  }
}
.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
}
.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid white;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.selectboxit-list .selectboxit-option-anchor {
  color: #333333;
}
.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: $color-dark-text;
  background-color: $color-light-bg-alt3;
  background-repeat: repeat-x;
}
.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}

@mixin compressed{
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 10;
}

@mixin bordered {
  border: 2px solid $color-sjoskum;
  // border-right: 2px solid $color-sjoskum;
  // border-left: 2px solid $color-sjoskum;
  // border-bottom: 2px solid $color-dark-text;
}

.form-select-container{
  .selectboxit {padding-left: 5px;}
  .selectboxit-option-icon-container {display:none;}
  display: inline-block;
  width: 100%;
  min-width: 100%;

  label {
    color: $color-dark-text;
    @include font-form-field-title;
    margin-bottom: 0px;

  }
  .selectboxit-options a {
    line-height: 34px;
    height: 34px;
    padding-left: 4px;
  }
  &.compressed {
    position: relative;
    label {
      @include font-special-form-title;
      @include compressed;
    }

    .selectboxit-text {
      color: $color-dark-text;
      position: relative;
      top: 7px;
    }
  }

  &.small {
    label {
      @include font-form-field-title;
      margin-bottom: 0px;
    }
    .selectboxit-container .selectboxit {
      height: 36px;
    }
    .selectboxit-arrow-container {
      width: 36px;
    }
    .selectboxit-container span, .selectboxit-container .selectboxit-options a {
      line-height: 36px;
      height: 36px;
    }

    .selectboxit .selectboxit-arrow-container .selectboxit-arrow {
      margin: 0 auto;
      position: absolute;
      top: 5px;
      right: 0;
      left: 0;
      font-size: 10px;
      color: white;
      text-align: center;
      font-size: 14px;

    }
  }

  &, .lightbg &{
    .selectboxit-container .selectboxit{
      @include bordered;

    }
    .selectboxit-list{
      border-right: 2px solid $color-dark-text;
      border-left: 2px solid $color-dark-text;
      border-bottom: 2px solid $color-dark-text;
    }
  }
  // .darkbg{

  // }

  &.mini {
    .selectboxit-arrow-container {
      background-color: white;
    }
    .selectboxit-default-arrow {
      border-top: 4px solid $color-dark-text;
    }
    .selectboxit-container .selectboxit {
      height: 29px;
    }
    .selectboxit-arrow-container {
      width: 20px;
    }
    .selectboxit-container span, .selectboxit-container .selectboxit-options a {
      line-height: 29px;
      height: 29px;
    }
    .selectboxit .selectboxit-arrow-container .selectboxit-arrow {
      margin: 0 auto;
      position: absolute;
      top: 3px;
      right: 7px;
      left: 0;
      font-size: 10px;
      color: $color-dark-text;
      text-align: center;
      height: 29px;
    }

    &, .lightbg & {
      .selectboxit-container .selectboxit-options li {border-bottom: none}
      .selectboxit {
        background-color: $color-light-bg-alt3;
        border: none;
      }
      .selectboxit-container span, .selectboxit-container .selectboxit-options {
        background-color: $color-light-bg-alt3;
      }
      .selectboxit-list{
        border: none;
        li {
          border: none;
          &.selectboxit-selected a{
            background-color: $color-dark-bg;
            color: white;
          }
          a:hover{
            background-color: $color-dark-bg;
            color: white;
          }
        }
      }
      .selectboxit-option-icon-container {
        display:none;
      }
    }
  }
}

.form-input-container, .form-textarea-container {
  label {
    @include  font-form-field-title;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  input, textarea{
    border: 1px solid $color-discrete-border-alt2;
    height: 50px;
  }
  input[type='number'] {
    font-size: 1rem;
  }
  textarea {
    resize: none;
    overflow:auto;
  }
  &.compressed {
    position: relative;
    label {
      @include font-special-form-title;
      @include compressed;
    }
    input {
      padding-top: 25px;
    }
  }

  &, .lightbg &{
    input, textarea {
      @include bordered;
    }
  }
  &.small {
    input{
      height: 36px;

    }
  }
}

.pagination {
  &, .lightbg & {
    ul li a {
      background-color: $color-light-bg-alt3;
    }
  }

  .darkbg & {
    ul li a {
      background-color: $color-light-bg-alt1;
    }
  }
  ul {
    @include font-btn-S;
    color: $color-dark-text;
    text-align: center;

    li {
      margin-left: 5px;

      display: inline-block;

      a {
        color: $color-dark-text;
        display: block;
        border-radius: 100px;
        height: 24px;
        width:  24px;
        text-align:center;
        line-height: 24px;
      }
      &.dots {
        position: relative;
        top: 2px;
      }
      &.current-page a{
        background-color: $color-dark-bg;
        color: $color-light-bg-alt1;
      }
      &.next-page a {
        background-color: transparent;
      }
      &.prev-page a{
        background-color: transparent;
      }
    }
  }
}

.checkbox-wrapper, .radiobutton-wrapper {
  input[type='checkbox'], input[type='radio'] {
    display: block;
    margin-bottom: 0;
    opacity: 0;
    + label {
      @include font-form-field-title;
      color: $color-dark-text;
      margin-left: 0;
    }
    + label:before {
      content: " ";
      width: rem-calc(21px);
      height: rem-calc(21px);
      margin-right: rem-calc(10px);
      float: left;
      background-color: $color-light-bg-alt1;

      &, .lightbg & {
        border: 2px solid $color-dark-border;
      }
      .darkbg & {
        //border: 2px solid $color-discrete-border-alt2;
        border: 2px solid $color-dark-border;
      }
    }
    &:disabled + label {
      opacity: 0.5;
    }
  }
  input[type='checkbox'] {
    &:checked + label:before {
      background: url('../../public/images/checkbox-icon.png') no-repeat center center;
      background-color: $color-light-bg-alt1;
    }
  }
  input[type='radio'] {
    + label:before {
      border-radius: 50%;
    }
    &:checked + label:before {
      background: url('../../public/images/radio-icon.png') no-repeat center center;
      background-color: $color-light-bg-alt1;
    }
  }
}

.slider{
  label, input{
    color: $color-dark-text;
    @include font-form-field-title;
  }
  input[type="text"]{
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .ui-slider-horizontal .ui-state-default {
    background: #ffffff url("../../public/images/slider-handler.png") no-repeat center center;
    top: -.40em;
    height: 27px;
    width: 27px;
    border: none;
  }
  .ui-slider-handle:focus{
    outline: 0;
  }
  .ui-widget-header {
    background: $color-dark-bg;
  }
  .ui-widget-content{
    border: none;
    &, .lightbg &{
      background: $color-light-bg-alt3;
    }
    .darkbg &{
      background: $color-light-bg-alt1;
    }
  }
  .ui-corner-all{
    border-radius: 25px;
  }
}



.form-datepicker-container {
  .datepicker-content {
    width: 100%;
  }

  .input-datepicker {
    position: relative;

    label {
      @include font-form-field-title;
    }
    .icon-calendar {
      position: absolute;
      font-size: 26px;
      right: 0;
      color: white;
      background-color: #181f2f;
      line-height: 48px;
      height: 48px;
      width: 48px;
      text-align: center;
      cursor: pointer;
    }
    input {
      margin-bottom:0;
      height: 50px;
      .darkbg & {
        border: 1px solid $color-discrete-border-alt2;
      }
      // &, .lightbg & {
      //   border: 2px solid $color-dark-border;
      // }
    }
    &.small {

      input {
        height: 36px;
      }
      .icon-calendar {
        line-height: 36px;
        height: 36px;
        width: 36px;
        font-size: 20px;
      }
    }
    &.compressed {
      position: relative;
      label {
        @include font-special-form-title;
        @include compressed;
      }
      .datepicker {
        color: $color-dark-text;
        padding-top: 25px;
      }
    }
    &[data-always-visible="true"]{
      input, label {
        display: none;
      }
    }
    &[data-always-visible="false"]{
      .date-info {
        display: none;
      }
    }
  }
}


/* ERRORS */
.has-error {
  input {
      border: 2px solid $color-warning !important;
  }
  &.form-datepicker-container {
    input {
      border: 2px solid $color-warning !important;;
    }
  }
  .selectboxit-container{
    border: 2px solid $color-warning  !important;;
  }

  .input-datepicker.visible{
    border: 2px solid $color-warning  !important;;
  }
}
