/*

Since styles in general are a mess
Fixes are done as overrides

*/

h2 {
    font-size: 1.5rem !important;
    color: $color-havsdjup;
    @media (max-width: 1024px) {
        font-size: 1.2rem !important;
    }
    &.title {
        font-size: 2.1rem !important;
        color: $color-havsdjup;
        @media (max-width: 1024px) {
            font-size: 1.5rem !important;
        }
    }
}

.feed-textbox.row.collapse {
    h4 {
        margin-bottom: 0.5em !important;
    }
    p {
        line-height: 1.2 !important;
        margin-top: 0.5em !important;
    }
}

body #ch_formSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & > #ch_search {
        margin: 0;
    }
    & > label {
        display: block;
        margin: 0;
        top: auto;
        left: auto;
        padding: 0 1rem;
        font-size: 1.5rem;
    }
}

.ch_joblist {
    margin: 1rem 0 !important;
}
