@import 'foundation/components/global';

.main-menu-container {
    position: relative;
    z-index: 999;

    /** Hide menu when bookingWidget fixed windows is open */
    @media (max-width: 600px) {
        .bv-widget-noscroll-small-device & {
            display: none;
        }
    }
}

.main-navigation {
    position: relative;
    background-color: $color-kust;

    .desktop-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 9999;
        transition: all 0.3s ease-in-out;
    }

    .title-area,
    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo-wrapper {
        justify-content: flex-start;
        height: rem-calc(45px);
        @media (max-width: 500px) {
            padding-left: 10px;
        }

    }

    svg {
        width: 100%;
        max-height: 85px;
        color: $color-havsdjup;
        fill: #fff;
        max-width: 210px;
        vertical-align: middle;

        @media (max-width: 500px) {
            max-width: 180px;
            padding-top: 15px;
        }
    }

    &.stick {
        // .desktop-menu {
        //     border-bottom: 0.1875rem solid $color-havsdjup;
        // }
        svg {
            padding-top: 5px;
        }
        .main-navigation-center {
            .top-bar {
                border-bottom-color: transparent;

                .top-bar-section {
                    ul {
                        &.title-area {
                            height: rem-calc(45px);
                        }
                    }
                }
            }
        }
    }

    // .icon-basket-container {
    //   position: relative;
    // }
    // .icon-basket_empty {
    //   color: $color-dark-bg;
    //   font-size: rem-calc(21);
    //   margin-top: rem-calc(11);
    //   position: absolute;
    //   right: 17px;

    //   .icon-basket-added-items {
    //     @include font-tiny-menu-text;
    //     background-color: $color-warning;
    //     border-radius: 100px;
    //     color: $color-light-text-alt1;
    //     padding: rem-calc(1) rem-calc(5px);
    //     position: absolute;
    //     right: -10px;
    //     top: 1px;
    //   }
    // }
    #menu-expanded-button {
        div {
            transition: all 0.3s;
            backface-visibility: hidden;
        }
        &.on {
            .one {
                transform: rotate(45deg) translate(5px, 5px);
            }
            .two {
                opacity: 0;
            }
            .three {
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }
    }

    .icon-menu {
        @include font-tiny-menu-text;

        a {
            color: $button-font-color;

            &:visited {
                color: $button-font-color;
            }
        }

        background-color: $color-kust;
        height: rem-calc(50);
        padding-top: rem-calc(6px);
        text-align: center;
        text-indent: rem-calc(-3px);
        width: rem-calc(50);
        .icon-menu-hamburger-line {
            background-color: $button-font-color;
            border: rem-calc(1px) solid $button-font-color;
            border-radius: rem-calc(8px);
            display: block;
            height: rem-calc(3px);
            margin-bottom: rem-calc(4px);
            margin-left: rem-calc(-3px);
            width: rem-calc(28px);
            &:first-child {
                margin-top: rem-calc(1px);
            }
        }
    }
    .menu-expanded-container {
        @include font-mainmenu-item;
        color: $color-light-text-alt1;
        position: absolute;
        display: none;
        z-index: 10;
        background-color: $color-kust;
        li {
            padding-left: rem-calc(20px);
            height: rem-calc(45px);
            line-height: rem-calc(45px);
            &:nth-child(odd) {
                border-bottom: 1px solid $color-havsdjup;
                border-right: 1px solid $color-havsdjup;
            }
            &:nth-child(even) {
                border-bottom: 1px solid $color-havsdjup;
            }

            a {
                color: $color-light-text-alt1;

                &.wa-button {
                    padding: 0.2rem 1rem;
                    font-size: 0.7rem;
                    font-weight: 400;
                    line-height: 1rem;
                }
            }
        }
    }

    .main-navigation-bottom.row {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    .main-navigation-bottom {
        clear: both;

        a {
            @include font-small-text-bold;
            background-color: $color-lightgold-bg;
            border-right: 1px solid $color-light-gold-border;
            color: $color-dark-text;
            display: block;
            height: rem-calc(36px);
            padding-top: rem-calc(13px);
            text-align: center;
            &:last-child {
                border: none;
            }
        }
    }
    /* colors are in _settings.scss*/
    @media #{$medium-up} {
        .main-navigation-content {
            //max-width: $row-width;
            margin: 0 auto;
            @include clearfix();
        }

        .main-navigation-top .top-bar {
            // height: rem-calc(28px);

            .top-bar-section {
                background-color: $color-havsdjup;

                li {
                    background-color: $color-havsdjup;
                    padding: rem-calc(9px) 0 rem-calc(9px) rem-calc(18px);

                    &:first-child {
                        padding-left: 0;
                    }

                    a {
                        @include font-topmenu-item;
                        background-color: $color-havsdjup;
                        padding: 0;
                        // line-height: rem-calc(18px);
                        font-size: 0.725rem;
                        font-weight: 600;
                    }

                    &.active {
                        color: $color-havsdjup;
                        // text-decoration: underline;
                    }
                }
            }
        }

        .main-navigation-center {
            background-color: $color-kust;
            .top-bar {
                // min-height: rem-calc(84px);
                height: auto;
                background-color: $color-kust;
                // border-bottom: rem-calc(2px) solid $color-havsdjup;
                box-sizing: content-box;
                .title-area {
                    height: rem-calc(84px);

                    img {
                        max-height: 23px;
                    }
                }

                .top-bar-section {
                    ul {
                        align-items: center;

                        &.title-area {
                            height: rem-calc(84px);
                        }

                        li {
                            background-color: $color-kust;

                            &:last-child a {
                                //padding: 0 0 0 rem-calc(25px);
                                padding: 0;
                            }

                            a {
                                @include font-mainmenu-item-bold;
                                //background-color: $color-light-bg-alt1;
                                color: $color-white;
                                // line-height: rem-calc(84px);
                                padding: 0 rem-calc(14px);
                                background: transparent;

                                &:hover {
                                    // border-bottom: 2px solid $color-gryning;
                                    color: $color-havsdjup;
                                    //     border-bottom: 2px solid $color-gryning;
                                    // }
                                }

                                &.active {
                                    background-color: $color-gryning;
                                    a {
                                        background-color: $color-lightgold-bg;
                                    }
                                }

                                &.wa-button {
                                    padding: 0.2rem 1rem;
                                    font-size: 0.7rem;
                                    font-weight: 400;
                                    line-height: 1rem;

                                    @media #{$medium-up} {
                                        padding: 0.25rem 1rem;
                                        font-size: 0.8rem;
                                    }
                                }
                            }

                            &.active {
                                // border-bottom: 1px solid $color-gryning;

                                a {
                                    // border-bottom: 1px solid $color-gryning;
                                    color: $color-gryning;
                                }
                            }
                        }
                    }
                }
            }
        }

        // .icon-basket_empty {
        //   position: relative;
        //   width: 52px;
        //   display: block;
        //   margin: 0;
        //   text-align: center;
        //   right: auto;
        //   font-size: rem-calc(26px);
        //   .icon-basket-added-items {
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     right: auto;
        //     margin-top: -17px;
        //     margin-left: 7px;
        //   }
        // }

        // .sticky.fixed {
        //   &.main-navigation-center {
        //     min-height: rem-calc(66px);

        //     .title-area {
        //       img {
        //         max-height: 17px;
        //       }
        //     }

        //     .top-bar{
        //       min-height: rem-calc(66px);
        //     }

        //     .top-bar-section {
        //       ul li {
        //         line-height: rem-calc(66px);
        //       }
        //     }
        //   }
        // }
    }
}
