.sticky-anchorlink {
  background-color: $color-light-bg-alt4;
  .sub-nav {
    padding: rem-calc(29px) rem-calc(36px) rem-calc(36px);
  }
  h3 {
    @include font-sticky-anchorlink;
    margin: rem-calc(7px) 0 rem-calc(8px) 0;
  }
  .active a {
    color: $color-discrete-text-alt1;
    &:before {
      @extend .iconcontainer;
      @extend .icon-arrow_right;
      font-size: rem-calc(10px);
    }
  }
  .delimiter {
    height: rem-calc(1px);
    width: 100%;
    background-color: $color-light-border-alt3;
    display: block;
  }
}