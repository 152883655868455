.productpage-wrapper {
  .image-carousel-container {
    margin-bottom: 0;
  }
  .image-carousel-thumbs-container {
    margin-top: rem-calc(3px);
  }
  .title-wrapper {
    margin-top: rem-calc(36px);
    margin-bottom: rem-calc(37px);
    h1 {
      color: $color-large-headline;
      display: inline-block;
      margin-right: rem-calc(55px);
    }
    a {
      @include font-paragraph-bold;
      text-transform: uppercase;
      .icon-arrow_right {
        font-size: rem-calc(10px);
        vertical-align: middle;
      }
    }
  }
  .product-information-wrapper {
    padding-bottom: 8px;

    .main-title {
      color: $color-large-headline;
      margin: 0;
    }
    .centered {
      display: table;
      width: 100%;
      margin-bottom: rem-calc(24px);
      margin-top: rem-calc(30px);
      .centered-box {
        float: left;
        display: table-cell;
        vertical-align: middle;
        &:first-child{
          float: right;
        }
        .tell-a-friend-wrapper {
          text-align: right;
          .tell-a-friend-title {
            margin-right: rem-calc(11px);
            display: inline-block;
          }
          .share-btn {
            float: none;
            margin-right: 0;
            display: inline-block;
          }
        }
      }
    }
  }
  .similar-products-wrapper {
    margin-bottom: rem-calc(82px);
  }
  .inspiration-wrapper {
    .inspiration-content-wrapper {
      height: rem-calc(392px);
      > *:first-child {
        height: 100%;
      }
    }
    .background-image {
      background-size: cover;
      &.image-one {
        background: url("https://images.unsplash.com/17/unsplash_527bf56961712_1.JPG?q=80&fm=jpg&s=64624a9470620c57827914dacb1f8f0d") no-repeat left center;
      }
    }
  }
}

