.information-icon-special {
    border: 1px solid black;
    width: 1rem;
    border-radius: 100px;
    font-size: 0.8rem;
    height: 1rem;
    display: inline-block;
    text-align: center;
    line-height: 1rem;
    position: relative;
    left: -1rem;
    background-color: white;
}
.select-label {
    label {
        display: inline-block;
    }
    .information-icon-special {
        left: 0;
    }
}
.has-tip:hover, .has-tip:focus {
    border-bottom: 1px solid black;
    color: inherit;
}