@mixin fontSettings(){
  -moz-font-feature-settings:"ss04" 1;
  -moz-font-feature-settings:"ss04=1";
  // -ms-font-feature-settings:"ss04" 1;
  // -o-font-feature-settings:"ss04" 1;
  -webkit-font-feature-settings:"ss04" 1;
  font-feature-settings:"ss04" 1;
  strong{
    font-family: 'Apercu-Bold';
    font-weight: inherit;
  }
}

// @font-face {
//   font-family: 'GT-Haptik-Bold';
//   src: url("../fonts/GT-Haptik-Bold.eot");
//   src: url("../fonts/GT-Haptik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Bold.woff") format("woff"), url("../fonts/GT-Haptik-Bold.ttf") format("truetype"), url("../fonts/GT-Haptik-Bold.svg") format("svg");
// }

// @font-face {
//   font-family: 'GT-Haptik-Bold-Oblique';
//   src: url("../fonts/GT-Haptik-Bold-Oblique.eot");
//   src: url("../fonts/GT-Haptik-Bold-Oblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Bold-Oblique.woff") format("woff"), url("../fonts/GT-Haptik-Bold-Oblique.ttf") format("truetype"), url("../fonts/GT-Haptik-Bold-Oblique.svg") format("svg");
// }
@font-face {
  font-family: 'Apercu-Regular';
  src: url("../fonts/newFonts/Apercu-Regular.otf") format("truetype");
}
@font-face {
  font-family: 'Apercu-Medium';
  src: url("../fonts/newFonts/Apercu-Medium.otf") format("truetype");
}
@font-face {
  font-family: 'Apercu-Bold';
  src: url("../fonts/newFonts/Apercu-Bold.otf") format("truetype");
}
@font-face {
  font-family: 'Mencken-Bold';
  src: url("../fonts/newFonts/MenckenProHeadNarrow-Bold.otf") format("truetype");
}
// @font-face {
//   font-family: 'GT-Haptik-Medium';
//   src: url("../fonts/GT-Haptik-Medium.eot");
//   src: url("../fonts/GT-Haptik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Medium.woff") format("woff"), url("../fonts/GT-Haptik-Medium.ttf") format("truetype"), url("../fonts/GT-Haptik-Medium.svg") format("svg");
// }

// @font-face {
//   font-family: 'GT-Haptik-Medium-Oblique';
//   src: url("../fonts/GT-Haptik-Medium-Oblique.eot");
//   src: url("../fonts/GT-Haptik-Medium-Oblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Medium-Oblique.woff") format("woff"), url("../fonts/GT-Haptik-Medium-Oblique.ttf") format("truetype"), url("../fonts/GT-Haptik-Medium-Oblique.svg") format("svg");
// }

// @font-face {
//   font-family: 'GT-Haptik-Regular';
//   src: url("../fonts/GT-Haptik-Regular.eot");
//   src: url("../fonts/GT-Haptik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Regular.woff") format("woff"), url("../fonts/GT-Haptik-Regular.ttf") format("truetype"), url("../fonts/GT-Haptik-Regular.svg") format("svg");
// }

// @font-face {
//   font-family: 'GT-Haptik-Regular-Oblique';
//   src: url("../fonts/GT-Haptik-Regular-Oblique.eot");
//   src: url("../fonts/GT-Haptik-Regular-Oblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Haptik-Regular-Oblique.woff") format("woff"), url("../fonts/GT-Haptik-Regular-Oblique.ttf") format("truetype"), url("../fonts/GT-Haptik-Regular-Oblique.svg") format("svg");
// }

@mixin font-small-text {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(10px); /*0.625em*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: uppercase;
}

@mixin font-small-text-bold {
  @include fontSettings();
  font-family: "GT-Haptik-Bold";
  font-size: rem-calc(10px); /*0.625em*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: uppercase;
}

@mixin font-mainmenu-item {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-mainmenu-item-bold {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}
@mixin font-date-title {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(23px); /*1.6875em*/
  line-height: rem-calc(37px); /*2.25em*/
  text-transform: none;
}
@mixin font-date-number {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(28px); /*1.6875em*/
  line-height: rem-calc(40px); /*2.25em*/
  text-transform: none;
}
@mixin font-bookdate-search-btn {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(18px); /*1.0625em*/
  line-height: rem-calc(16px); /*1.333em*/
  text-transform: uppercase;
}
@mixin font-superbig-title {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(24px); /*3.375em*/
  line-height: rem-calc(36px); /*4.209em*/
  text-transform: none;
  @media #{$medium-up}{
    font-size: rem-calc(45px);
    line-height: rem-calc(48px);
  }
}

@mixin font-h1 {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(36px); /*2.25em*/
  line-height: rem-calc(50px); /*3.125em*/
  text-transform: none;
}

@mixin font-h2 {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(27px); /*1.6875em*/
  line-height: rem-calc(36px); /*2.25em*/
  text-transform: none;
}

@mixin font-h3 {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(16px); /*1em*/
  line-height: rem-calc(24px); /*1.5em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(16px); /*0.875em*/
    line-height: rem-calc(21px); /*1.333em*/
  }
}

@mixin font-h4 {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(17px); /*1.0625em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: uppercase;
}

@mixin font-textbox-quote {
  @include fontSettings();
  font-family: "Apercu-Medium";
  font-size: rem-calc(24px); /*1.5em*/
  line-height: rem-calc(36px); /*2.25em*/
  text-transform: none;
}

@mixin font-textbox {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(18px); /*1.5em*/
  line-height: rem-calc(21px); /*2.25em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(38px); /*1.5em*/
    line-height: rem-calc(50px); /*2.25em*/
  }
}

@mixin font-btn-S {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(12px); /*0.75em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: uppercase;
}

@mixin font-btn-M {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: uppercase;
}

@mixin font-btn-L {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(24px); /*1.5em*/
  line-height: rem-calc(36px); /*2.25em*/
  text-transform: uppercase;
}

@mixin font-paragraph-small {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(20px); /*0.75em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(16px); /*0.875em*/
    line-height: rem-calc(21px); /*1.333em*/
  }
}

@mixin font-paragraph-small-bold {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(12px); /*0.75em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(14px); /*0.875em*/
    line-height: rem-calc(21px); /*1.333em*/
  }
}
@mixin font-paragraph-small-bold-mobile {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(15px); /*0.75em*/
  line-height: rem-calc(20px); /*1em*/
  text-transform: none;
}
@mixin font-paragraph-small-regular {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(12px); /*0.75em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(14px); /*0.875em*/
    line-height: rem-calc(21px); /*1.333em*/
  }
}

@mixin font-paragraph {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(18px); /*1.333em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

@mixin font-paragraph-bold {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(18px); /*1.333em*/
  text-transform: none;
  @media #{$medium-up} {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }
}

@mixin font-paragraph-oblique {
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.625em*/
  line-height: rem-calc(18px); /*0.875em*/
  text-transform: none;
}

@mixin font-paragraph-oblique-small {
  font-family: "Apercu-Regular";
  font-size: rem-calc(12px); /*0.625em*/
  line-height: rem-calc(16px); /*0.875em*/
  text-transform: none;
}

@mixin font-checklist {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(24px); /*1.5em*/
  text-transform: none;
}

@mixin font-form-field-title {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-form-field-value {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-tiny-menu-text {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(9px); /*0.563m*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: uppercase;
}
@mixin font-topmenu-item {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(10px); /*0.625em*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: uppercase;
}

@mixin font-tiny-menu-text-bold {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(9px); /*0.563m*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: uppercase;
}

@mixin font-small-print {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(10px); /*0.625em*/
  line-height: rem-calc(14px); /*0.875em*/
  text-transform: none;
}

@mixin font-infobox-small-print {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(10px); /*0.625em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: none;
}

@mixin font-infobox-sticky-title {
  @include fontSettings();
  font-family: "Apercu-Medium";
  font-size: rem-calc(16px); /*1.5em*/
  line-height: rem-calc(21px); /*2.25em*/
  text-transform: none;
  @media #{$medium-up}{
    font-family: "Apercu-Bold";
    font-size: rem-calc(14px); /*1.5em*/
    line-height: rem-calc(21px); /*2.25em*/
    text-transform: uppercase;
  }
}

@mixin font-infobox-sticky {
  @include fontSettings();
  font-family: "Apercu-Medium";
  font-size: rem-calc(12px); /*1.5em*/
  line-height: rem-calc(16px); /*2.25em*/
  text-transform: none;
  @media #{$medium-up}{
    font-family: "Apercu-Bold";
    font-size: rem-calc(14px); /*1.5em*/
    line-height: rem-calc(16px); /*2.25em*/
    text-transform: none;
  }
}

@mixin font-prodbox-list-title {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(24px); /*1.5em*/
  line-height: rem-calc(36px); /*2.25em*/
  text-transform: none;
}

@mixin font-prodbox-grid-title {
  @include fontSettings();
  font-family: "Mencken-Bold";
  font-size: rem-calc(22px); /*1.2em*/
  line-height: rem-calc(24px); /*1.5em*/
  text-transform: none;
}

@mixin font-prodbox-paragraph {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-checkout-item-title {
  @include fontSettings();
  font-family: "Apercu-Medium";
  font-size: rem-calc(24px); /*1.5em*/
  line-height: rem-calc(36px); /*2.25em*/
  text-transform: none;
}

@mixin font-checkout-item-paragraph {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-special-form-title {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(10px); /*1em*/
  line-height: rem-calc(4px); /*1.5em*/
  text-transform: uppercase;
}

@mixin font-checkout-title {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(16px); /*1em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}
@mixin font-checkout-title-italic {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(16px); /*1em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}

@mixin font-checkout-price {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(24px); /*1.5em*/
  line-height: rem-calc(16px); /*1em*/
  text-transform: none;
}

@mixin font-sticky-anchorlink {
  @include fontSettings();
  font-family: "Apercu-Regular";
  font-size: rem-calc(16px); /*1em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}


@mixin font-weather {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(14px); /*0.875em*/
  line-height: rem-calc(18px); /*1.333em*/
  text-transform: none;
  @media #{$medium-up}{
    font-size: rem-calc(12px); /*0.875em*/
    line-height: rem-calc(18px); /*1.333em*/
  }
}
@mixin font-weather-date {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(24px);
  line-height: rem-calc(30px);
  text-transform: none;
}

@mixin font-receipt-small {
  @include fontSettings();
  font-family: "Apercu-Bold";
  font-size: rem-calc(12px); /*0.875em*/
  line-height: rem-calc(21px); /*1.333em*/
  text-transform: none;
}
