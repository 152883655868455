//@import "compass/css3/user-interface";

.ghost-btn{
  user-select: none;
  cursor: pointer;
  text-align: left;
  //vertical-align: middle;
  display: inline-block;
  position: relative;
  transition-property: background-color, color;
  transition-duration: 0.2s;

  .darkbg &{
    color: $color-white;
    border: rem-calc(3px) solid $color-white;
    &:hover{
      border: rem-calc(3px) solid $color-white;
      color: $color-kust;
      background-color: $color-white;
    }
  }
  &, .lightbg &{
    color: $color-dark-text;
    border: rem-calc(3px) solid $color-kust;
    &:hover{
      color: $color-sjoskum;
      background-color: $color-kust;
    }
  }


  &,&.small {
    @include font-btn-S;
    padding: rem-calc(11 - 3px) 3.125rem rem-calc(9 - 3px) 1rem;
    //    padding: 0 1rem;
    @media (max-width: 500px) {
      margin-bottom: 20px;
  }
    .arrow{
      right: 1em;
      font-size: rem-calc(11px);
    }
  }
  &.medium {
    @include font-btn-M;
    padding: rem-calc(13px) 4.2rem rem-calc(10px) 1.75rem;
    .arrow{
      right: 1.75em;
    }
  }
  .arrow{
    float: right;
    position: absolute;
    top:50%;
    height: 2em;
    line-height: 2em;
    margin-top: -1em;
  }


}


@mixin cta-btn($bg-color, $text-color, $border-color) {
  background-color: $bg-color;
  color: $text-color;
  border-bottom: rem-calc(3px) solid $border-color;
  border-top: rem-calc(1px) solid transparent;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  &:active {
    border-bottom: rem-calc(3px) solid rgba($border-color, 0.5);
    border-top: rem-calc(1px) solid rgba($border-color, 0.5);
  }
}

.cta-btn {
  user-select: none;
  cursor: pointer;
  text-align: center;
  padding: 0 1.75rem;
  width: auto;
  display: inline-block;
  box-sizing: content-box;

  &.disabled:hover,
  &.disabled:focus,
  &[disabled]:hover,
  &[disabled]:focus,
  &.button.disabled:hover,
  &.button.disabled:focus,
  &.button[disabled]:hover,
  &.button[disabled]:focus{
    background-color: $color-inactive;
  }

  //Is being reused in "only classes"
  @mixin small{
    @include font-btn-S;

    //height: rem-calc(33px);
    //line-height: rem-calc(33px);
    padding-top: rem-calc(11px);
    padding-bottom: rem-calc(6px);
    //line-height: rem-calc(16px); /*1em*/
  }
  &.small {
    @include small();
  }

  //Is being reused in "only classes"
  @mixin medium{
    @include font-btn-M;

    //height: rem-calc(47px);
    //line-height: rem-calc(47px);
    //line-height: rem-calc(21px); /*1.333em*/

    padding-top: rem-calc(15px);
    padding-bottom: rem-calc(11px);
  }
  &, &.medium {
    @include medium();
  }

  //Is being reused in "only classes"
  @mixin large{
    @include font-btn-L;

    //height: rem-calc(64px);
    //line-height: rem-calc(64px);
    //line-height: rem-calc(36px); /*2.25em*/
    padding-top: rem-calc(16px);
    padding-bottom: rem-calc(12px);
  }
  &.large {
    @include large();
  }



  &.dark {
    @include cta-btn($color-kust, $color-white, $color-dark-bottom-border);
    &:hover{
      background-color: $color-sjoskum;
      border: 2px solid $color-sjoskum;
      color: $color-havsdjup;
    }
  }
  &.go {
    // @include cta-btn($color-go, $color-light-text-alt1, $color-go-bottom-border);
    background-color: $color-gryning;
    &:hover{
      background-color: $color-gryning;
    }
  }
  &.fb {
    @include cta-btn($color-fb, $color-light-text-alt1, $color-fb-border);
    &:hover{
      background-color: $color-fb-hover;
    }
  }
  &.warning {
    @include cta-btn($color-warning, $color-light-text-alt1, $color-warning-bottom-border);
    &:hover{
      background-color: $color-warning-hover;
    }
  }
  &.inactive,&:disabled {
    @include cta-btn($color-inactive, $color-light-text-alt1, $color-inactive-bottom-border);
  }
  .value {
    margin-right: 1.3333em;
    color: $color-lightgold-text;
    text-transform: none;
  }

  @include create-only-classes(small-btn){
    @include small();
  }
  @include create-only-classes(medium-btn){
    @include medium();
  }
  @include create-only-classes(large-btn){
    @include large();
  }


  &.with-icon{
    position: relative;
    &,&.small {
      padding-left: $ms-18;
      .icon{
        margin-right: $ms-14;
        font-size: rem-calc(11px);
      }
    }
    &.medium {
      padding-left: $ms-18;
      .icon{
        margin-right: $ms-14;
        //left: 1.75em;
        font-size: rem-calc(18px);
        line-height: rem-calc(18px);
      }
    }
    // .icon{
    //   //float: left;
    //   //position: absolute;
    //   //top:50%;
    //   //height: 2em;
    //   //line-height: 2em;
    //   //margin-top: -1em;
    // }
  }
}


@mixin share-btn($color) {

}

.share-btn {
  border-radius: 50%;
  width: rem-calc(36px);
  height: rem-calc(36px);
  float: left;
  margin-right: 5px;

  .icon {
    color: #fff;
    line-height: rem-calc(36px);
    width: 100%;
    text-align: center;
    display: block;
  }
  &.fb {
    background-color: $color-fb;
  }
  &.mail {
    background-color: $color-gold;
    cursor:pointer;
  }
}