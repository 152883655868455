
@mixin cross($color,$size){
  //background-color: $color-warning;
  position: relative;
  color: $color-dark-text;
  //border: $size solid $color;
  &:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 0px;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: $size;
    background-color: $color;
    top: 50%;
    margin-top: -$size/2;
    //padding: 0px 1px;
    box-sizing: content-box;
    z-index: -1;
    border-radius: $size;
  }
  &:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 0px;
  }
}

// .datepicker {
// }

.input-datepicker {
  &.visible{
    > input,> label{
      display: none;
    }
    .calendar-container {
      position: relative;
      z-index: 0;
      .date-info {
        display: block;
      }
    }
  }
  .calendar-container {
    top: auto !important;
    left: auto !important;
    position: absolute;
    z-index: 1000;
    background-color: $color-light-bg-alt1;

    .date-info {
      width: 100%;
      height: rem-calc(71px);
      background-color: $color-medium-bg;
      position: relative;
      display: none;
      .day-name {
        @include font-paragraph;
        width: 40%;
        line-height: 70px !important;
        color: $color-discrete-text-alt2;
        display: inline-block;
        text-align: center;
        position: relative;
        float: left;
      }
      .year {
        @include font-paragraph;
        color: $color-discrete-text-alt2;
        display: inline-block;
        width: 40%;
        line-height: 70px !important;
        text-align: center;
        position: relative;
        float: right;
      }
      .date {
        width: 20%;
        text-align: center;
        display: inline-block;
        color: white;
        position: relative;
        top: 10px;
        .month {
          display: block;
          @include font-paragraph-bold;
          text-transform: uppercase;
          margin-left: 2px;
        }
        .day {
          font-size: 36px;
          display: block;
          @include font-h1;
          line-height: 1.825rem;
        }
      }
    }
    .month-picker {
      height: 32px;
      line-height: 32px;
      border-bottom: 1px solid $color-discrete-border-alt2;
      position: relative;
      .prev-month, .next-month {
        display: inline;
        position: absolute;
        top: -1px;
        font-size: 13px;
        color: $color-dark-text;
      }
      .next-month {
        right: 15px;
      }
      .prev-month {
        left: 15px;
      }
      .current-month {
        display: inline;
        @include font-paragraph-bold;
      }
    }

    &.datepicker-content {
      box-shadow: none;
      ul {
        width: 100%;
        margin: 0 auto;
        li {
          height: (100% / 6); //with for every day of the week
          width: (100% / 7); //with for every day of the week
          border: 1px solid white;
          box-sizing: border-box;
        }
      }
      .datepicker-week {
        height: (100%/7);
        //height: 100%;
        li {
          @include font-paragraph-bold;
          line-height: 28px;
          text-align: center;
          height: 100%;
        }
      }
      .month-picker .current-month {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
      }
      .datepicker-days {
        height: (100%-(100%/7));
      }
      .datepicker-week,
      .datepicker-days {
        padding-left: 3px;
        li {
          display: table;
          z-index: 10;

          &.disabled {
            @include font-paragraph-oblique;
          }
          //      &.selected{
          //        background-color: $color-dark-bg;
          //        border-top: 3px solid black;
          //        border-bottom: 3px solid black;
          //        border-right: 3px solid black;
          //        border-left: 3px solid black;
          //        border-right: none;
          //        border-left: none;
          //        &.selected-first {
          //          border-left: 3px solid black;
          //        }
          //        &.selected-last {
          //          border-right: 3px solid black;
          //        }
          //      }
          // &:hover {
          // }


          .inner {
            border-radius: 100px;
            @include font-paragraph-bold;
            box-sizing: border-box;
            display: table-cell;
            vertical-align: middle;
            &.day.available, &.day.not-available {
              color: white;
            }
            &.day.available {
              background-color: $color-go;
            }
            &.day.not-available {
              color: white !important;
              background-color: $color-warning;
              @include cross($color-inactive,1px);

              z-index: 1;
            }

            &.day.selected,
            &.day.selected-dates{
              background-color: $color-dark-bg;
              color: white;
            }
            &.hover-select {
              background-color: $color-dark-bg !important;
              color: white !important;
            }
            &.hover-select.not-available {
              //background-color: $color-warning !important;
              border: 1px solid $color-warning;
              @include cross($color-warning,2px);
              z-index: 1;
            }
          }

          &[data-type="day not-available"] {
            //@include cross();
          }

          &.disabled .inner{
            color: $color-inactive;
          }
        }
      }
    }
  }
}
