
.footer {
    padding-top: 24px;
    color: $color-light-text-alt1;
    background-color: $color-havsdjup;
    margin-top: $ms-54;
    color: $color-white;

    a {
        color: $color-light-text;
    }

    h4 {
        color: $color-light-text-alt1;
    }

    p {
        @include font-paragraph-small-bold;

        @media (max-width: 500px) {
            @include font-paragraph-small-bold-mobile;
        }
    }


    ul {
        li {
            @include font-paragraph-small-bold;

            @media (max-width: 500px) {
                @include font-paragraph-small-bold-mobile;
            }
        }
    }

    .footer-column {
        @include font-paragraph-small-bold;

        @media (max-width: 500px) {
            @include font-paragraph-small-bold-mobile;
        }
    }

    .column-title-bold {
        @include font-paragraph-small-regular ;

        @media (max-width: 500px) {
            @include font-paragraph-small-bold-mobile;
        }
    }

    .footer-group {
        margin-bottom: rem-calc(18px);
    }

    .footer-four-columns-column {
        margin-bottom: 20px;

        .row .columns {
            span {
                display: block;
                width: 100%;
                height: 18px;
            }

            &:nth-child(odd) span {
                background-color: gray;
                height: 18px;
            }

            &:nth-child(even) span {
                background-color: #ccc;
                height: 18px;
            }
        }

        &:last-child {
            float: none !important;
        }
    }

    .byline p {
        @include font-small-print;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .logos-bottom {
        margin-top: 1rem;
        padding-bottom: rem-calc(36px);

        img {
            margin: 0 rem-calc(10px) 0 rem-calc(10px);
        }
    }

    @media #{$medium-up} {
        padding-top: $ms-36;

        .row > .footer-column {
            padding: 0 $ms-36;
        }

        .row > .footer-four-columns-column {
            padding: 0 $ms-36;

            &:last-child {
                float: right !important;
            }
        }

        .ghost-btn {
            margin-bottom: $ms-36;
        }
    }

    .sm-links {
        position: relative;
        margin: 0 auto 1rem;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style-type: none;

            $sm-icons: 24px;

            li {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: $sm-icons;
                height: $sm-icons;
                margin: 0 0 0 1rem;
                padding: 0;
                opacity: .5;
                cursor: pointer;
                transition: all .3s ease-in;

                &:first-child {
                    margin: 0;
                }

                a {
                    max-height: 100%;
                }

                img {
                    max-height: $sm-icons;
                }

                &.facebook {
                    img {
                        max-height: 22px;
                    }
                }

                &.linkedin {
                    img {
                        filter: grayscale(100%);
                    }
                }

                &:hover {
                    opacity: 1;

                    img {
                        filter: grayscale(0%);
                    }
                }
            }
        }
    }

    .emailSubscriptionForm {
        position: relative;
        margin: 1rem 0;
        width: 100%;
        &:first-child {
            margin-top: 0;
        }

        * {
            color: white;
        }
        .-loader {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: 0.25s ease;
            pointer-events: none;
        }
        .-content {
            opacity: 1;
            transition: 0.25s ease;
        }
        .-title {
            margin: 0 0 1em;
            text-transform: uppercase;
        }
        .-description {
            &, * {
                font-size: 0.9rem;
            }
            *:first-child {
                margin-top: 0;
            }
            *:last-child {
                margin-bottom: 0;
            }
        }
        .-field {
            margin: 0.5rem 0;
        }
        .is--email {
            position: relative;
            padding-top: 1rem;
            input {
                display: block;
                width: 100%;
                padding: 0 1rem;
                font-size: 0.9rem;
                font-weight: 700;
                color: $color-dark-text;
                background: $color-sjoskum;
                outline: none;
                border: none;
                border-radius: 0.4rem;
                transition: 0.25s ease;
                &:focus,
                &:not([value=""]) {
                    & + span {
                        color: white;
                        top: 0;
                        transform: scale(0.75);
                    }
                }
                &:focus {
                    box-shadow: 0 2px 20px $color-go;
                }
            }
            span {
                position: absolute;
                left: 1rem;
                top: 1.5rem;
                transform-origin: left top;
                transition: 0.25s ease;
                pointer-events: none;
                color: $color-dark-text;
                &::after {
                    content: "*";
                    color: $color-warning;
                    padding-left: 0.1em;
                }
            }
        }
        .is--agreement {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            line-height: 1.2;
            input {
                display: block;
                margin: 0 0.5rem 0 0;
                width: 1.8em;
                height: 1.8em;
                cursor: pointer;
                &:focus {
                    box-shadow: 0 2px 10px $color-go;
                }
            }
        }
        .-submit {
            button {
                margin: 1rem 0 0;
                background-color: transparent;
                color: $color-white;
                &:hover, &:focus {
                    background-color: $color-white;
                    color: $color-havsdjup;
                    i {
                        color: $color-havsdjup;
                    }
                }
            }
        }
        .-message {
            margin-top: 1rem;
            padding: 1rem;
            font-size: 0.9rem;
            background-color: rgba(white, 0.25);
            transition: 0.25s ease;
            &.is--hidden {
                height: 0;
                padding: 0;
            }
            &.is--success {
                background-color: $color-go;
            }
            &.is--failure {
                background-color: $color-warning;
            }
        }
        &.is--sending {
            .-content {
                opacity: 0.25;
            }
            .-loader {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &-customBottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2rem 4rem 4rem 0 !important;
        .-text {
            margin-bottom: 1rem;
            font-weight: 400;

            &:nth-child(2) {
                order: 1;
                text-align: right;
            }
            &:nth-child(3) {
                order: 3;
                text-align: left;
            }
            a {
                font-weight: 400;
            }
        }
        .-logos {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;
            padding: 0 1rem;
            a {
                display: block;
                margin: 0 0.5rem;
            }
            img {
                height: 4rem;
                color: $color-havsdjup;
            }
        }

        @media (max-width: 900px) {
            flex-direction: column;
            padding: 2rem 0 !important;
            .-text {
                order: initial !important;
                text-align: center !important;
            }
            .-logos {
                order: 1;
            }
        }
    }
}
