#bookingWidgetContainer {
    position: relative;
    display: block;
    min-height: rem-calc(315);
    max-width: 100%;
    width: rem-calc(800);
    margin: 0 auto;
    border: solid 1rem $color-white;
    background-color: $color-kust;
    transform: translate(0, -35%);
    z-index: 95;
    @include font-sticky-anchorlink;

    label {
        @include font-infobox-sticky-title;
      }

    @media #{$small-only} {
        transform: none;
    }

    &.bv-Standard1ColumnPlate {
        .bv-widget {
            .bv-item-date {
                .bv-dateboxes {

                    .bv-type {
                        background-color: $color-eld;
                        @include font-date-title;
                    }
                        .bv-date {
                            @include font-date-number;
                        }
                }
            }

            label {
                border: 1px solid $color-sjoskum;
                @include font-textbox;
                // width: 80%;

                span {
                    @include font-sticky-anchorlink;
                }
            }

            .bv-datepicker {
                @include font-sticky-anchorlink;
                    .bv-state-active {
                        background-color: $color-eld;
                        @include font-date-title;
                    }
                    .bv-state-departure {
                        background-color: $color-eld !important;
                        @include font-date-title;

                        &::before {
                            background-color: $color-eld;
                        }
                    }
                @media (max-width: 600px) {
                    bottom: 0 !important;

                }
            }

            .bv-guestpicker {
                @include font-weather;
                    .bv-lbl-title {
                        background-color: $color-kust;
                    }

                    .bv-spin-icn {
                        color: $color-white;
                        background-color: $color-kust;
                    }
                @media (max-width: 600px) {
                    bottom: 0 !important;
                }
                .bv-guestpicker a {
                    @include font-weather;
                }
            }

            .bv-gui-btn {
                background: none;
                border: rem-calc(3px) solid $color-white;
                // max-width: 80%;
                @include font-bookdate-search-btn ;

                &:hover {
                    background-color: $color-white;
                    color: $color-kust !important;
                }
            }

            .bv-close {
                background-color: $color-havsdjup;
            }
        }
    }
}
