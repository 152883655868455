
.datepicker-container {
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    line-height: 30px;
    float: left;
    position: absolute;
    z-index: 201312;
}

.datepicker-arrow,
.datepicker-arrow:after {
    border: 5px solid transparent;
    display: block;
    height: 0;
    width: 0;
}

.datepicker-arrow {
    border-bottom-color: #9cf;
    margin: 0 10px;
    position: relative;
}

.datepicker-arrow:after {
    border-bottom-color: #fff;
    content: " ";
    left: -5px;
    position: absolute;
    top: -4px;
}

.datepicker-content {
    border: 1px solid #ccc;
    border-top-color: #9cf;
    box-shadow: 0 0 3px #ccc;
    //width: 210px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.datepicker-prev,
.datepicker-next {
    font-size: 18px;
}

.datepicker-content ul:before,
.datepicker-content ul:after {
    content: "";
    display: table;
}

.datepicker-content ul:after {
    clear: both;
}

.datepicker-content ul {
    margin: 0;
    padding: 0;
    width: 102%;
    *zoom: 1;
}

.datepicker-content li {
    background-color: #fff;
    cursor: pointer;
    float: left;
    height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 30px;
}

.datepicker-content .col-1 {
    width: 30px;
}

.datepicker-content .col-2 {
    width: 60px;
}

.datepicker-content .col-3 {
    width: 90px;
}

.datepicker-content .col-4 {
    width: 120px;
}

.datepicker-content .col-5 {
    width: 150px;
}

.datepicker-content .col-6 {
    width: 180px;
}

.datepicker-content .col-7 {
    width: 210px;
}

.datepicker-content li:hover {
    background-color: #eee;
}

.datepicker-content .selected,
.datepicker-content .selected:hover {
    color: #36f;
}

.datepicker-content .disabled,
.datepicker-content .disabled:hover {
    color: #ccc;
}

.datepicker-years li,
.datepicker-months li {
    height: 52.5px;
    line-height: 52.5px;
    width: 52.5px;
}

.datepicker-week li,
.datepicker-week li:hover {
    background-color: #fff;
    cursor: default;
}
