@font-face {
  font-family: 'marholmen_glyphs';
  src: url('../../fonts/marholmen_glyphs.eot');
  src: url('../../fonts/marholmen_glyphs.eot#iefix') format('embedded-opentype'),
       url('../../fonts/marholmen_glyphs.woff') format('woff'),
       url('../../fonts/marholmen_glyphs.ttf') format('truetype'),
       url('../../fonts/marholmen_glyphs.svg#marholmen_glyphs') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'marholmen_glyphs';
    src: url('../font/marholmen_glyphs.svg?44424914#marholmen_glyphs') format('svg');
  }
}
*/
 
 .iconcontainer, [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "marholmen_glyphs";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  //text-decoration: inherit;
   text-decoration: none !important;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-basket_empty:before { content: '\e800'; }
.icon-calendar:before { content: '\e801'; }
.icon-check:before { content: '\e802'; }
.icon-circle:before { content: '\e803'; }
.icon-fb:before { content: '\e804'; }
.icon-grid_icon:before { content: '\e805'; }
.icon-list_icon:before { content: '\e806'; }
.icon-mail:before { content: '\e807'; }
.icon-marholmen_logo:before {
  color: #2d7498;
  content: '\e808';
  font-size: 12.6rem;
  line-height: 7rem;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}
.icon-pdf:before { content: '\e809'; }
.icon-printer:before { content: '\e80a'; }
.icon-search:before { content: '\e80b'; }
.icon-trashcan:before { content: '\e80c'; }
.icon-watertemp:before { content: '\e80d'; }
.icon-pen:before { content: '\e80e'; }
.icon-close:before { content: '\e80f'; }
.icon-arrow_down:before { content: '\e810'; }
.icon-arrow_left:before { content: '\e811'; }
.icon-arrow_right:before { content: '\e812'; }
.icon-arrow_up:before { content: '\e813'; }
.icon-warning:before { content: '\e814'; }