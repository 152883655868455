
.filter-sidebar{
  border: 3px solid $color-light-border-alt3;
  color: $color-dark-text;
  h2{
    color: $color-large-headline;
    text-align: center;
    margin: rem-calc(28px) 0;
  }
  ul{
    margin: 0;
  }
  .accordion .accordion-navigation{
    .accordion-title{
      background-color: $color-light-bg-alt3;
      padding: rem-calc(7px) 0 rem-calc(8px) rem-calc(29px);
      h4{
        display: inline-block;
        margin: 0;
      }
    }
    .content{
      padding: rem-calc(18px) rem-calc(24px) rem-calc(28px);
    }
  }
  .icon-accordion{
    font-size: rem-calc(13px);
    margin-right: rem-calc(10px);
  }
  .icon-accordion:before{
    @extend .icon-arrow_right;
  }
  .active .icon-accordion:before{
    @extend .icon-arrow_down;
  }
}