
.sort-bar-mobile {
  background-color: $color-light-bg-alt3;
  .tabs {
    background-color: white;
    padding-right: rem-calc(4px);
    padding-left: rem-calc(4px);
    .tab-title {
      border-right: rem-calc(2px) solid white;
      min-width: 33%;
      a {
        @include font-paragraph-small-bold;
        background-color: $color-dark-bg;
        color: white;
        height: rem-calc(40px);
        line-height: rem-calc(40px);
        margin: 0;
        text-align: center;
        padding: 0 10px;

      }
      &:last-child {
        border-right: none;
      }
      &.active a {
        background-color: $color-light-bg-alt3;
        color: $color-dark-bg;
      }
    }
  }
  .tabs-content {
    padding: rem-calc(4px);
    @include font-paragraph-small;
    > .content {
      position: relative;
      top: 50%;
    }
    section {
      padding: rem-calc(30px) rem-calc(16px);
      span {
        margin-top: rem-calc(30px);
      }
      a {
        float: right;
      }
    }
  }
}

.sort-bar-desktop {
  height: 54px;
  line-height: 54px;
  border: 3px solid $color-light-bg-alt3;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 15px;
  padding-left: 6px;
  .sort-icon {
    display: inline-block;
    background-color: $color-light-bg-alt3;
    margin: 0;
    padding: 0;
    width: 42px;
    height: 36px;
    position: relative;
    font-size: 24px;
    .icon-grid_icon, .icon-list_icon{
      line-height: 36px;
      text-align: center;
      width: 100%;
      height: 100%;
      &:before {
        width: 32px;
        margin: 0;
        padding: 0;
      }
    }

    color: $color-discrete-text-alt2;
    &.active {
      border: 2px solid $color-dark-bg;
      background-color: white;
      color: $color-dark-text;
      .icon-grid_icon, .icon-list_icon {
        line-height: 32px;
        //top: -10px;
        //left: -1px;
      }
    }
    &:not(:first-child) {
      margin-left: 2px;
    }
    > span {
      position: absolute;
    }
    .icon-grid_icon, .icon-list_icon {
      //top: -9px;
      //left: 1px;

    }
  }
  .sort-container {
    > span {
      margin-left: 30px;
      margin-right: 5px;
    }
    .selectboxit {
      width: 86px !important;
    }
    .form-select-container {
      margin-right: 5px;
      width: auto;
      min-width: 0;
    }
  }
  .right {
    display: inline-block;
    > span {
      position: relative;
      top: -9px;
      @include font-paragraph-bold;
      line-height: 54px;
      a {
        color: $color-dark-text;
        text-decoration: underline;
      }

    }
  }
  .sort-container > span {
    @include font-paragraph-bold;
    line-height: 54px;
    position: relative;
    top: -12px;
  }
  .sort-container {
    display: inline-block;
  }
}