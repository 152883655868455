.mobile-menu,
.main-navigation-top,
.title-wrapper,
.checkout-item-feedback,
.ghost-btn,
.footer,
.cta-btn
{
  display:none;
}

body {
  margin-top: 15px;
}

.title-area {
  text-align: center;
  li img {
    width: 200px;
  }
}
.receipt {
  margin-top: 10px;
}