.hero-image-startpage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: rem-calc(80px) 0;
    background-color: #999;
    background-size: cover;

    @media #{$medium-up} {
        padding: rem-calc(150px) 0;
    }

    .hero-image-startpage-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .darkbg &,
    &.darkbg {
        color: $color-white;
    }

    &,
    .lightbg & {
        color: $color-white;
    }

    .arrow-down {
        margin-top: $ms-21;
        margin-bottom: $ms-10;
        display: block;
    }

    .arrow-down-medium-up {
        position: absolute;
        left: 50%;
        bottom: rem-calc(10px);
    }

    .text-container,
    .text-container h1 {
        @include font-superbig-title;
        margin-left: $ms-21;
        color: #fff;
    }

    .button-container {
        margin-left: $ms-21;

        &.movie-button {
            display: none;
        }
    }

    @media #{$medium-up} {
        margin-bottom: rem-calc(48px);
        background-color: #666;

        .text-container {
            margin-left: $ms-54;
        }

        .button-container {
            margin-top: $ms-21;
            //margin-bottom: rem-calc(100px);
            margin-left: $ms-54;

            &.movie-button {
                display: inherit;
            }
        }
    }
}
