.product-infobox-sticky {
    margin: 0;
    padding: 0;
    background-color: $color-lightgold-bg;
    height: 82px;
    .right {
        margin-right: 11px;
        margin-top: 5px;
        color: $color-dark-bg;
        h3 {
            @include font-infobox-sticky-title;
            text-align: right;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
        p {
            margin-bottom: 0;
            text-align: right;
            @include font-infobox-sticky;
            span {
                font-size: rem-calc(28px);
            }
        }
    }
    .column p {
        text-align: right;
    }
    .column h3 {
        @include font-infobox-sticky-title;
        text-align: right;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .right-button {
        margin-right: 5px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    @media #{$medium-up} {
        .right {
            margin: rem-calc(15px) $ms-18 rem-calc(15px) $ms-18;
        }

        .column {
            h3 {
                @include font-infobox-sticky-title;
                text-align: right;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: rem-calc(5px);
            }
            p {
                span {
                    font-size: 24px;
                    line-height: 24px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.prodbox {
    position: relative;

    color: $color-havsdjup;
    @media (max-width: 500px) {
        height: auto;
    }

    &.with-button {
        padding-bottom: 100px;
    }

    p {
        @include font-prodbox-paragraph;
    }
    span a {
        color: $color-havsdjup;
        text-decoration: underline;
    }
    .gold-border {
        margin-bottom: rem-calc(21px);
        margin-top: rem-calc(13px);
        height: rem-calc(3px);
        width: rem-calc(119px);
        background-color: $color-sjoskum;
        display: block;
    }
    .text-wrapper {
        margin-top: rem-calc(
            15px
        ); // TODO: Kolla hur margin ser ut pa denna, det beror pa hur gutters ser ut for columns
        a {
            color: $color-havsdjup;
            &:hover {
                color: $color-kust;
            }
        }
    }

    .image-wrapper {
        position: relative;
        left: 0;
        top: 0;
    }
    .overlay-image {
        position: absolute;
        top: rem-calc(10px);
        right: rem-calc(10px);
        max-height: 50%;
        width: auto;
    }
    &.grid {
        h4 {
            @include font-prodbox-grid-title;
            color: $color-kust;
        }
        .image-wrapper {
            text-align: center;
            padding: 1.2rem;
            background-color: $color-kust;
        }
        .cta-btn {
            display: block; // TODO: Borde bli positionerad maneullt(absolut?), detta tas senare
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: 1.125rem 0.5rem;
            color: $color-white;
            &:hover {
                color: $color-havsdjup;
            }
        }
    }
    &.list {
        h4 {
            @include font-prodbox-list-title;
        }
        .image-wrapper {
            margin: rem-calc(10px) 0px rem-calc(28px) 0px; // TODO: Kolla hur margin ser ut pa denna, det beror pa hur gutters ser ut for columns
        }
        .cta-btn {
            // TODO: Borde bli positionerad maneullt(absolut?), detta tas senare
            margin-bottom: 1.125rem;
        }
        .text-content {
            margin-left: 0;
        }
    }
    &.alt1 {
        margin-bottom: 5px;
        a {
            color: $color-havsdjup;
            &:hover {
                color: $color-kust;
            }
        }
    }

    // &.alt3 {
    //   background-color: $color-light-bg-alt3;
    // }
    // &.alt4 {
    //   background-color: $color-light-bg-alt4;
    // }
}
