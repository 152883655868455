.slick-arrow {
  position: absolute;
  top: 50%;
  width: 2rem;
  height: 4rem;
  margin: 0;
  padding: .5rem 0;
  background-color: $color-kust;
  color: $color-white;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  text-align: center;
  opacity: .5;
  border-radius: 5px 0 0 5px;
  transform: translateY(-50%);
  transition: all .3s ease-in;
  z-index: 10;

  &:hover,
  &:active {
    background-color: $color-sjoskum;
    color: $color-white;
    opacity: 1;
  }

  &:before {
    font-size: 1rem;
  }

  &.slick-next {
    right: 0;
  }

  &.slick-prev {
    left: 0;
    border-radius: 0 5px 5px 0;
  }
}

.image-carousel-container {
  position: relative;
  margin-bottom: 4px;

  ul {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      list-style: none;
    }
  }


  h1, h2, h3, h4 {
    font-size: 1rem !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    padding-left: 1rem !important;
    @media (max-width: 900px) {
      font-size: 1rem !important;
    }
  }
  p {
    margin-top: 0.5em !important;
    line-height: 1.2 !important;
    padding-left: 1rem !important;
  }

  .slick-slide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media #{$small-only}{
    .icon-container {
      display: none;
    }
  }
  .orbit-caption {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0;
    background-color: transparent;
    transform: translateY(-50%);
    .carousel-textbox {
      display: inline-block !important;
    }
    .row.collapse {
      &::before, &::after {
        display: none;
      }
    }
    h1 {
      display: inline;
      white-space: nowrap !important;
      width: 100%;
      float: none !important;
      padding-right: 2rem !important;
    }
    @media (max-width: 900px) {
      h1 {
        display: block;
        white-space: normal !important;
      }
    }
  }
  @media #{$medium-up} {
    .carousel-textbox {
      margin: 0;
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }
  .orbit-next, .orbit-prev {
    display: none;
  }
  .text-overlay {
    position: absolute;
    top: 0;
    .icon-container {
      a {
        display: block;
        width: rem-calc(28px);
        height: rem-calc(28px);
        background-color: $color-light-text-alt1;
        margin-top: rem-calc(80px);
        text-align: center;
        line-height: rem-calc(34px);
      }
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

.image-carousel-thumbs-container {
  position: relative;
  margin-top: .5rem;
  overflow: hidden;

  @media #{$small-only}{
    overflow-x: scroll;

    .icon-search {
      display: none;
    }
    .slick-arrow {
      display: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      position: relative;
    }
  }

  @media #{$medium-up}{
    .image-carousel-thumbs {
      //width: 5000px;
      //overflow: hidden;
      //position: relative;
      //left: 0;
      a {
        display: block;
        float: left;
        margin-right: 5px;
        position: relative;
        height: 117px;
        overflow: hidden;
      }

      .slick-current img {
        opacity: 0.4;
        filter: alpha(opacity=40); /* For IE8 and earlier */
      }
      a span {
        display: none;
        position: absolute;
        top: 20px;
        left: 53px;
        font-size: 54px;
        color: $color-dark-bg;
      }
      a:hover {
        img {
          opacity: 0.2;
          filter: alpha(opacity=20); /* For IE8 and earlier */
        }
        span {
          display: block;
        }
      }
      //}
    }
  }
}
