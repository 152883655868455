

.weather-module {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  max-width: $row-width;
  transform: translateX(-50%);

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: $ms-54;
    height: $ms-54;

    background-color: $color-dark-bg;
    color: $color-light-text;


    &.water{
      background-color: rgba($color-dark-bg, 0.8);
    }
    .icon{
      @include font-weather-date;
      line-height: rem-calc(22px);
    }
    .text {
      @include font-weather;
    }
    .uppercase{
      text-transform: uppercase;
    }
    @media #{$medium-up}{
      width: rem-calc(45px);
      height: rem-calc(62px);
      // background-color: $color-lightgold-bg;
      // color: $color-dark-text;;

      .icon{
        line-height: rem-calc(30px);
      }

      &.weather{
        background-color: rgba($color-dark-bg, 0.9);
        .icon{
          font-size: rem-calc(20px);
        }
      }
      &.water{
        background-color: rgba($color-dark-bg, 0.8);
        .icon{
          font-size: rem-calc(28px);
          line-height: rem-calc(25px);
        }
      }
    }
  }
}
