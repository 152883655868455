.hidden {
  display:none;
}

.vertical-center-container{
  display: table;
  height: 100%;
  width: 100%;
}
.vertical-center-content{
  display: table-cell;
  vertical-align: middle;
}


.row.full-width{
  max-width: 100%;
}

.content-width{
//  max-width: 73.5rem !important;
  margin: 0 auto !important;
}
  @mixin keep-ratio(){
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    position: relative;

    /* Other ratios */

    &.ratio2_1:before {
      padding-top: 50%;
    }

    &.ratio_cart_item:before{
      padding-top:100%;
    }
    &.ratio40:before {
      padding-top: 40%;
    }

    &.ratio20:before {
      padding-top: 20%;
    }

    &.ratio1_2:before {
      padding-top: 200%;
    }

    &.ratio4_3:before {
      padding-top: 75%;
    }

    &.ratio16_9:before {
      padding-top: 56.25%;
    }
    &.ratio11_7:before {
      padding-top: 63.63636363636363%;
    }
    .ratio-cal:before {
      padding-top: 93.69369369369369%;
    }
  }


.keep-ratio {
  @include keep-ratio();

}
@include create-only-classes(keep-ratio){
  @include keep-ratio();
}

@include create-only-classes(ratio-content){
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.absolute-in-middle, .ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.absolute-in-middle {
  margin: auto;
}


.darkbg {
  //  background-color: $color-dark-bg;
}

.lightbg {
  //background-color: $color-light-bg-alt1;
}
.degree {
  font-family: arial, helvetica, sans-serif;
  font-style: normal;
}

.arrow {
  font-style: normal;
  font-family: arial, helvetica, sans-serif;
}


.arrow-down {
  font-style: normal;
  font-family: arial, helvetica, sans-serif;
}

a{
  color: #fff
}
