.order-complete-wrapper {
  margin-top: rem-calc(50px);
  margin-bottom: rem-calc(90px);
  .main-title {
    color: $color-large-headline;
    margin-bottom: rem-calc(33px);
  }
  .checkout-item-feedback-wrapper {
    margin-bottom: rem-calc(37px);
  }
  .prodbox.grid .image-wrapper {
    margin-top: 0;
  }
  .receipt-wrapper {
    margin-top: rem-calc(39px);
  }
}
