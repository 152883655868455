.receipt {
  border: 3px solid $color-light-border-alt3;
  padding: rem-calc(28px);
  p,span {
    margin: 0;
    @include font-prodbox-paragraph;
  }
  .bold-font {
    @include font-mainmenu-item-bold;
  }
  .oblique-small-font {
    @include font-paragraph-oblique-small;
  }
  .discount-font {
    @include font-receipt-small;
  }
  .top-title {
    margin: 0;
    &.in-progress {
      text-transform: uppercase;
    }
    &.confirmation {
      text-transform: capitalize;
      color: $color-large-headline;
    }
  }
  .top-paragraph {
    margin-top: rem-calc(28px);
    margin-bottom: rem-calc(7px);
  }
  .top-last-button {
    margin-left: rem-calc(19px);
  }
  .item-title-wrapper {
    margin-bottom: rem-calc(21px);
  }
  .item-price-wrapper {
    margin-top: rem-calc(21px);
  }
  .child-items-title {
    margin: rem-calc(21px) 0px;
  }
  .delimiter {
    margin: rem-calc(21px) 0;
    display: block;
    background-color: $color-light-border-alt3;
    height: 3px;
  }
  .color-warning {
    color: $color-warning;
  }
  .previous-price {
    text-decoration: line-through;
    color: $color-discrete-text-alt1;
    margin: 0;
  }
  .vat-included {
    margin-top: rem-calc(54px);
  }
  .centered {
    display: table;
    width: 100%;
    margin-bottom: rem-calc(28px);
    .centered-box {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .child-item {
    margin-left: rem-calc(25px);
  }
}