@import 'settings';
@import 'foundation/foundation';

// Allows the use of rem-calc() or lower-bound() in your settings
@import 'foundation/functions';

@import 'foundation/components/dropdown';

@import 'modular-scale';

@import 'fontello/_marholmen_glyphs';
@import 'functions';
@import 'colors';
@import 'fonts';
//@import "desktop-font";
@import 'weather-icons';
@import 'datepicker';
@import 'gui_elements';

@import 'slick-carousel';

@import 'bookingWidget';

.articlepage-wrapper a {
    color: $color-kust;
    // text-decoration: underline;

    &:hover {
        text-decoration: none;
        color:$color-havsdjup;
    }
}
// .href-white-text a {
//     color:#fff;

//     &:hover {
//         text-decoration: none;
//     }
// }



p,
ul,
li,
ol {
    @include font-paragraph;
}

h1 {
    @include font-h1;
    color: $color-havsdjup
}
h2 {
    @include font-h2;
}
h3 {
    @include font-h3;
    color: $color-havsdjup
}
h4 {
    @include font-h4;
}

p {
    a {
        color: $color-large-headline;
        text-decoration: underline;
    }
}

@import 'helpers';
@import 'elements';
@import 'buttons';
@import 'navigation';
@import 'templates';

@import 'footer';
@import 'miscellaneous';

// @import "temp";
// @import "tests";

//@import "desktop/desktop-main";

@import 'mobile';
@import 'tablet';

@media print {
    @import 'print';
}

@import 'override';
