
.product-infobox {
  @include font-paragraph;
  background-color: $color-light-bg-alt4;
  color: $color-dark-text;
  padding: rem-calc(14px) rem-calc(16px) rem-calc(15px);

  .cta-btn{
    width: 100%;
    box-sizing: border-box;
  }

  min-height: 400px;

  h2 {
    margin-right: rem-calc(14px);
    span {
      font-size: rem-calc(14px);
    }
  }

  .discrete-border {
    height: 1px;
    width: 100%;
    background-color: $color-discrete-border-alt2;
    display: block;
  }

  .checklist-container {

    margin-bottom: rem-calc(36px);

    ul {
      @include font-checklist;
      list-style: none;
      margin-left: 0;
      margin-bottom: 0;
    }

    .columns {
      padding-left: rem-calc(16px);
    }
    .columns:first-child {
      padding-left: 0;
    }
    .checklist li{
      padding-left: 19px;
    }
    .checklist li:before {
      @extend .iconcontainer;
      @extend .icon-check;
      color: $color-checklist-bullet;
      font-size: rem-calc(9px);
      margin-right: rem-calc(10px);
      vertical-align: middle;
      margin-left: -19px;
    }
  }
  .gold-border {
    margin-bottom: rem-calc(15px);
    margin-top: rem-calc(8px);
    height: rem-calc(3px);
    width: rem-calc(119px);
    background-color: $color-gold-border;
    display: block;
  }
  .cta-btn {
    margin: rem-calc(25px) 0px 0px 0px;
    display: block;
  }

  .paragraph-bold, .paragraph-bold p, .accordion a {
    @include font-paragraph-small-bold;
  }
  p {
    @include font-paragraph-small;
  }
  .accordion .accordion-navigation > a, .accordion dd > a {
    padding: 0 ($ms-54 - $ms-36);
    line-height: $ms-50;
  }
  .accordion a {
    text-transform: uppercase;
  }
  .accordion .accordion-navigation > a, .accordion dd > a, .accordion .accordion-navigation > .content.active, .accordion dd > .content.active {
    background-color: inherit;
  }
  .accordion-icon {
    @extend .iconcontainer;
    @extend .icon-arrow_right;
    line-height: $ms-50;
    font-size: rem_calc(8px);
  }
  .active .accordion-icon:before {
    @extend .icon-arrow_down;
  }
  img {
    margin-bottom: rem-calc(18px);
  }
  [class*="block-grid-"] > li {
    padding: 0px;
    margin-top: rem-calc(27px);
    p {
      @include font-infobox-small-print;

    }
  }
  .cta-btn {
    margin: 0;
  }
  @media #{$medium-up}{

    .checklist-container {
      .columns {
        //padding-left: rem-calc(36px);
      }
      .columns:first-child {
        padding-left: 0;
      }
    }

    padding: rem-calc(32px) rem-calc(36px) rem-calc(33px);
    .cta-btn {
      margin: rem-calc(36px) 0px 0px 0px;
    }
    .gold-border {
      margin-top: $ms-21;
      margin-bottom: $ms-21;
      height: rem-calc(3px);
      width: rem-calc(119px);
      background-color: $color-gold-border;
      display: block;
    }
    h2 {
      @include font-h1();
      span {
        font-size: rem-calc(24px);
      }
      line-height: 21px;
    }
  }
}

.product-infobox-sticky {
  min-width: 0;
  max-width: none;
}
