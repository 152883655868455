.filter-wrapper {
    margin-top: rem-calc(36px);

    .row .filter-bar-wrapper {
        padding-right: rem-calc(20px);
    }

    .sort-bar-wrapper {
        margin-bottom: rem-calc(20px);
    }

    .pagination-wrapper {
        margin-top: rem-calc(28px);
        margin-bottom: rem-calc(64px);
    }

    .textbox-wrapper {
        h1 {
            color: $color-havsdjup;
        }

        p {
            color: $color-havsdjup;
            @include font-mainmenu-item-bold;
        }
    }

    .prodbox {
        border: 1px solid #FFFFFF;
        padding-top: 7px;
    }
}

.filter-page-spinner {
    display: none;

    img {
        position: absolute;
        z-index: 20;
        margin: auto;
        left: 0;
        top: 90px;
        right: 0;
    }

    position: relative;
}

.filter-objects-itemRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }
}

// .filter-objects-container {

// }
