@media #{$medium-only} {
    .checklist li {
        word-break: break-word;

    }

    .title-area {
        width: 100%;
        text-align: center;
        padding: 0;
        height: 60px !important;

        .name {
            height: 100%;
            width: auto;
            text-align: left;
            padding: 0;
            padding-left: 20px;
        }
    }

    .articlepage-wrapper {
        .form-datepicker-container {
            width: 70%;
        }
    }

    .main-navigation-center {
        ul {
            li {
                //          float:none;
                padding: 0 12px;
            }
        }

        .right {
            display: -webkit-flex;
            display: flex;
            justify-content: space-around;
            width: 100%;
            //        display:table;
            li {
                //            display:table-cell;
                //          padding: 0!important;
                a {
                    //            padding:0!important;
                }
            }
        }
    }

    .main-navigation .main-navigation-center .top-bar .top-bar-section ul li a {
        padding: 0;
    }

    .hero-image-startpage {
        .text-container {
            font-size: 1.975rem;
            line-height: 2.6875rem;
            margin-top: 40px;
            margin-bottom: 0;
        }
    }

    .feed {
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .prodbox .small-5 {
        padding-right: 0 !important;
    }

    .checkout-bundle .image-wrapper {
        margin-right: 0;
    }

    .checkout-bundle .text-wrapper {
        padding-left: 1rem;
    }

    .checkout-item-wrapper {
        .checkout-item {
            h4 {
                margin-top: 1.1rem;
            }

            img {
                object-fit: cover;
            }

            .columns {
                padding: 0 0.5rem !important;
            }
        }
    }

    .checkout-item-wrapper .centered .align-middle {
        display: block;
    }

    .checkout-item-wrapper .centered .align-middle > .columns {
        display: block;
    }

    .checkout-item-wrapper .checkout-item {
        height: auto;
    }

    .checkout-item-expanded-container .checkout-item-expanded-top .checkout-item-right {
        width: 451px;
    }

    .checkout-item-expanded-container {
        width: auto !important;
    }
}
